/* min-width: 992px and max-width: 1200px */
@media (min-width: 62em) and (max-width: 75em) {
  .header__item {
    height: 2vw !important;
  }
  .menu-toggle {
    display: block;
  }
  .header__navigation {
    width: 100%;
    display: block;
    align-items: center;
    height: 6%;
  }
  nav {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  nav ul {
    display: block;
  }
  nav ul {
    flex-direction: column;
    display: flex;
  }
  nav ul li {
    margin-top: 1rem;
  }
  nav ul li a {
    margin: 0;
    font-size: 1.3rem;
  }
  .overlay.menu-open,
  nav.menu-open {
    display: flex;
    transform: scale(1);
    opacity: 1;
    height: 100vh;
    width: 100%;
  }
  .overlay.menu-open,
  nav.menu-open li {
    display: block;
  }
  header {
    position: relative;
    overflow: visible;
  }
  nav ul li a h5,
  nav ul li a h5:visited {
    display: block;
    padding: 0 0.6vw;
    line-height: 1.2;
    font-size: 3vw;
    font-weight: 600;
    background: transparent;
    text-decoration: none;
  }
  .header__social-icon {
    display: none;
  }
  .header__logo {
    position: relative;
  }
  .header__logo {
    position: fixed;
    width: 100%;
    margin: 0 !important;
    height: 6%;
  }
  i.fas.fa-bars,
  i.fas.fa-times {
    font-size: 3rem;
  }
  body.mobile-nav .header__logo a img {
    width: 35%;
    top: 5%;
  }
  body.mobile-nav .header__logo__wave-svg {
    width: 45%;
  }
  .img-change {
    height: 10vh;
  }

  .bitwiser_block li {
    font-size: 2.5vw;
  }
  .challenge_description p {
    font-size: 2.5vw;
  }
  .header__logo a img {
    padding: 0;
    width: 20%;
  }
  section.experts.dark-wave {
    height: 29%;
  }
  .header__logo__wave-svg {
    width: 40%;
  }
  .footer-cookie {
    display: flex;
    flex-direction: row;
  }
  .tabs_wrapper .tab_content {
    overflow-y: auto;
  }
  .tabs_wrapper .tab_container {
    padding: 5vw;
    height: 100%;
  }
  .tabs_wrapper .tab_bg{
    width: 65% !important;
    left: 10% !important;
  }
  .project_bg2 .inner-banner__content h5 {
    font-size: 3.1vw;
  }
  .portfolio_detail_banner_img img{
    width: 70%;
  }
  .main-banner__content-illustration img {
    width: 40vw;
  }
  .bitwiser_block.shape li{
    left: 16% !important;
    width: 85% !important;
    font-size: 1.8vw !important;
  }
  .service_detail_page .footer ul.menu li a {
    font-size: 1.5vw !important;
  }
  .service_detail_page h2 {
    font-size: 2vw !important;
    margin-bottom: 0.5vh !important;
  }
  .service_detail_page h1.large {
    font-size: 3.2vw !important;
}
.service_detail_page h4 {
  margin: 0.4vh 0 3vh 0 !important;
}
.service_detail_page .btn {
  font-size: 2vw !important;
  padding: 0.8vh 3vw !important;
  margin: 0 0 3vh 0 !important;
}
.bitwiser.blog_detail {
  padding-top: 9.5vw;
}
}
