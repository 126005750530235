/* min-device-width: 992px and max-device-width: 1024px */
@media (min-device-width: 62em) and (max-device-width: 64em) and (orientation: landscape) {
  h1.large,
  .h1.large {
    font-size: 3vw;
    line-height: normal;
  }
  h1,
  .h1 {
    font-size: 2.5vw;
    line-height: normal;
  }
  h2,
  .h2 {
    font-size: 2vw;
    line-height: normal;
  }
  h3,
  .h3 {
    font-size: 2vw;
    line-height: normal;
  }
  h5,
  .h5 {
    font-size: 1.5vw;
    line-height: normal;
  }
  .tabs label {
    font-size: 1.5vw;
  }
  .blog__text h6 {
    font-size: 1.4vw;
  }
  .pb-120 {
    padding-bottom: 13vw !important;
  }
  .btn {
    font-size: 1.5vw;
    padding: 1.8vh 3.5vw;
  }
  .btn:active,
  .btn:hover,
  .btn.btn-bordered.active {
    padding: 1.8vh 3.5vw;
  }
  a.btn.btn-bordered.accordion {
    width: 25%;
    padding: 1.8vh 2vw;
    font-size: 1.5vw;
    margin: 0 !important;
  }
  .button-group {
    margin: 4.99vh 0 0 0;
  }
  section.bg-dark.new_bg {
    height: 75% !important;
  }
  .header__logo a img {
    padding: 1vw 0 0 0 !important;
    margin: 0.5vw 0 0 2vw;
  }
  .header__logo a img {
    width: 16% !important;
    top: 0%;
    left: 1%;
    position: fixed;
  }
  .img {
    height: 19vw !important;
  }
  .img-change {
    height: 17% !important;
  }
  .menu-toggle {
    top: 50%;
    position: absolute;
  }
  .header__logo a.stickylogo img {
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    padding: 0 !important;
    margin: 0vw 0 0 1.5vw;
  }
  .header__logo__wave-svg {
    width: 30%;
  }
  .main-banner {
    height: 140%;
  }
  i.fas.fa-bars,
  i.fas.fa-times {
    font-size: 2rem;
  }
  section.experts.dark-wave {
    height: 50%;
  }
  div#different h5 {
    font-size: 1.5vw;
  }
  a.btn.btn-bordered.accordion {
    margin: 1vw 0 0 0;
  }
  .bg-resume .button-group {
    float: none;
  }
  section.inner-banner.white__bg {
    height: 65%;
  }
  .portfolio__box {
    padding: 0 2vw;
  }
  .capabilities-list ul li h5 {
    font-size: 1.5vw;
    line-height: normal;
  }
  .serve__blue {
    padding: 0.5vw;
  }
  .specialization_img {
    width: 19%;
    padding: 0.9vw 0 0 0 !important;
  }
  .specialization_text {
    text-align: left;
  }
  li.specialization_item:nth-child(even) .specialization_text {
    padding: 0 40px 0 0px;
  }
  button.slick-prev.slick-arrow {
    left: 30%;
  }
  .slider.slick-initialized.slick-slider {
    margin: 0 auto;
  }
  section.inner-banner.project-bg {
    height: 60%;
  }
  .tabs_wrapper .tab_content {
    padding: 0;
  }
  section.technology .tabs label {
    padding: 2vw 4.3vw;
  }
  section.bg-dark.new_bg.project {
    height: 85% !important;
  }
  img.benefits_icon {
    margin: 1vw 1vw 0 0px;
  }
  .technology__icon {
    width: 10.3vw;
    height: 10.3vw;
    line-height: 10.3vw;
  }
  .technology #two:checked ~ .panels #two-panel .fa-stack-1x {
    line-height: 2.3vw !important;
  }
  .technology #two:checked ~ .panels #two-panel .fa-chevron-circle-left:before,
  .technology
    #two:checked
    ~ .panels
    #two-panel
    .fa-chevron-circle-right:before {
    font-size: 4.4vw;
  }
  .main-banner__content {
    flex-direction: row;
  }
  .main-banner__content-illustration img {
    height: 90vh;
    margin: 0 0 0 2vw;
  }
  #return-to-top {
    width: 4.5vw;
    height: 4.5vw;
  }
  #chat {
    width: 4.5vw;
    height: 4.5vw;
    bottom: 7.5vw;
  }
  #share {
    width: 4.5vw;
    height: 4.5vw;
    bottom: 2vw;
  }
  .social-buttons {
    bottom: 2vw;
    right: 7.5vw;
  }
  .social-button i {
    font-size: 1.6vw;
  }
  .social-button {
    width: 4.5vw;
    height: 4.5vw;
  }
  #chat i,
  #return-to-top i,
  #share i {
    left: 1.4vw;
    top: 1.2vw;
    font-size: 2vw;
  }
  .services__service-box {
    flex: 1 0 25%;
  }
  .services__icon:after {
    width: 11.2vw;
    height: 11.2vw;
  }
  .services__icon {
    width: 10.3vw;
    height: 10.3vw;
    line-height: 10.3vw;
  }
  .inner-banner {
    height: 90%;
  }
  section.experts.dark-wave {
    height: 60%;
  }
  .services__box {
    padding: 2vw 0 !important;
  }
  .service__content.mini-container {
    flex-direction: row;
  }
  section.header.black.active .img {
    visibility: hidden;
  }
  .service__content.mini-container {
    margin: 11vw auto 0 auto;
  }
  .service_illustration img.vert-move {
    height: 80vh;
    margin: 0;
    padding: 1vw;
  }
  section.inner-banner.white__bg {
    height: 100%;
  }
  section.bg-dark.new_bg {
    height: 250% !important;
  }
  body.mobile-nav nav ul li a h5,
  body.mobile-nav nav ul li a h5:visited {
    font-size: 2.5vw;
  }
  .hover-underline-animation::after {
    bottom: -1vw;
  }
  nav ul > li:last-of-type {
    top: auto;
  }
  body.mobile-nav nav ul {
    flex-direction: column;
    display: flex;
    position: absolute;
    padding: 0vw 0 15vh 0;
    top: 25%;
    height: 90vh;
    overflow: auto;
    width: 100%;
  }
  .solutions_box {
    min-height: 42vw;
  }
  img.img-list-src {
    width: 70%;
    margin: 2vw 0 0 0;
    padding: 0;
  }
  li.specialization_item::before {
    left: -4vw;
  }
  li.specialization_item:nth-child(even) .specialization_img {
    padding: 0;
  }
  li.specialization_item:nth-child(even) .specialization_img img.img-list-src {
    width: 70%;
    margin: 2vw 1vw 0 0;
    padding: 0;
  }
  .error-msg {
    font-size: 2vw;
  }
  .bitwiser.blog_detail {
    padding-top: 9.5vw;
  }
}
