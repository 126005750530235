/* min-width: 1024px and max-width: 1264px */
@media (min-width: 64em) and (max-width: 79em) {
  h1.large,
  .h1.large {
    font-size: 3.2vw;
    line-height: normal;
  }
  h1,
  .h1 {
    font-size: 2.9vw;
    line-height: normal;
  }
  h2,
  .h2 {
    font-size: 2.5vw;
    line-height: normal;
    margin: 0.5vw 0;
  }
  h3,
  .h3 {
    font-size: 2.2vw;
    line-height: normal;
  }
  h4,
  .h4 {
    font-size: 1.9vw;
    line-height: normal;
  }
  h5,
  .h5 {
    font-size: 1.5vw;
    line-height: normal;
    margin: 0.5vw 0;
  }
  h6,
  .h6 {
    font-size: 1.3vw;
    line-height: normal;
    font-weight: 400;
  }
  a {
    font-size: 2.2vw;
    line-height: normal;
  }
  .tabs label {
    font-size: 1.5vw;
  }
  .width-6,
  .width-7,
  .width-8,
  .width-5,
  .width-4 {
    width: 50%;
  }
  .main-container {
    width: 90%;
  }
  .mini-container {
    width: 85%;
  }
  .btn {
    font-size: 1.5vw;
    padding: 1vh 3.5vw;
  }
  .main-banner__content-text .btn {
    padding: 1vh 3.5vw;
  }
  .btn:active,
  .btn:hover,
  .btn.btn-bordered.active {
    padding: 1vh 3.5vw;
  }
  .button-group {
    margin: 1.99vh 0 0 0;
  }
  .button-group a {
    margin: 0.99vh 0.5vw;
  }
  .main-title h5 {
    padding: 0 8vw;
  }
  ul.bitwiser_block {
    padding: 0 5vw;
  }
  span.fa-stack i {
    font-size: 4vw;
  }
  .tab .pt-250 {
    padding-top: 13vw !important;
  }
  .pt-250 {
    padding-top: 19vw !important;
  }
  .pb-300 {
    padding-bottom: 24vw !important;
  }
  section.technology .tabs label {
    padding: 2vw 5.2vw;
    font-size: 3vw;
  }

  .header__logo a img {
    width: 20%;
  }
  .header__logo__wave-svg {
    width: 33%;
  }
  .main-banner {
    height: 60%;
  }
  .main-banner__content {
    flex-direction: row;
  }
  .main-banner__content-illustration img {
    padding: 0;
    width: 100vw;
    margin: 3vw 2vw 0 0;
  }
  .main-banner__content {
    width: 90%;
    padding: 5vw 0 0 0;
  }
  section.technology .tabs label {
    padding: 0.5vw 3.3vw;
  }
  .services__service-box {
    flex: 1 0 30%;
  }
  .services__icon:after {
    width: 10.2vw;
    height: 10.2vw;
  }
  .services__icon {
    width: 9.3vw;
    height: 9.3vw;
    line-height: 9.3vw;
  }
  img.services__shape {
    top: 4vw;
    height: 12.5vw;
  }
  a.hover-underline-animation {
    font-size: 1.5vw;
  }
  #return-to-top,
  #chat,
  #share {
    font-size: 1.4vw;
    width: 10vw;
    height: 10vw;
  }
  #return-to-top {
    bottom: 15vw;
  }
  #chat i,
  #return-to-top i,
  #share i {
    left: 3.4vw;
    top: 2.5vw;
    font-size: 4vw;
  }
  .contact__bg-hover {
    padding: 4.2vw 3.4vw;
    margin: 3vw 1.67vw;
    width: 44%;
    float: left;
  }
  .connect__part {
    flex: 1 0 50%;
  }
  li.contact__bg-hover h2 {
    font-size: 1.8vw;
  }
  .quote__content {
    flex-direction: row;
  }
  .quote__content-description {
    font-size: 1.3vw;
  }
  h1.large.quote__content-title {
    font-size: 2.2vw;
  }
  .blog__text h3 {
    font-size: 1.6vw;
  }
  .form-group input {
    font-size: 1.5vw;
    height: 4.4vw;
    padding: 0 2vw;
    margin: 1vw 0 0 0;
  }
  .form-group textarea {
    font-size: 1.5vw !important;
    height: 11vw;
    padding: 1vw;
    margin: 1vw 0 0 0;
  }
  .form-checkbox label {
    font-size: 1.5vw;
    height: 4.4vw;
  }
  .col-12 button {
    margin: 1vw 0 0 0;
  }
  .footer ul.menu li a,
  section.footer h5,
  .footer__bottom__copyright h5 {
    font-size: 1.4vw;
    line-height: 2;
  }
  ul.footer__social li a {
    font-size: 2.5vw;
  }
  .footer__bottom h6.mar-0 {
    font-size: 1.5vw;
  }
  .inner-banner__background-img h1.large {
    font-size: 3.4vw;
  }
  .inner-banner {
    height: 50%;
    margin: 0px 0 4vw 0;
  }
  a.more,
  a.less {
    font-size: 1.4vw;
  }
  body.mobile-nav .img-change {
    height: 7vh;
  }
  .img-change {
    height: 6vh;
  }
  .services__box {
    flex-direction: row;
    padding: 2vw 0 !important;
  }
  .reverse {
    flex-direction: row !important;
  }
  .dark-wave {
    height: 50%;
  }
  .technology__icon {
    margin: 0 auto 1.5vw;
  }
  /* .owl-wrapper-outer {
    padding: 0.7vw 0 !important;
  } */
  .owl-stage-outer {
    padding: 4.7vw 0 !important;
  }
  #testimonial .owl-stage-outer {
    padding: 6vw 0 !important;
  }
  .experts.dark-wave h1.large {
    font-size: 2.15vw;
  }
  .card h4 {
    font-size: 1.2vw;
    line-height: normal;
    margin: 0;
  }
  .card h1.large {
    font-size: 3vw;
    margin: 0;
  }
  .column {
    padding: 1.5%;
    flex: 1 0 10%;
  }
  .counter {
    -webkit-flex-flow: wrap;
  }
  .counter {
    display: -webkit-flex;
    -webkit-flex-flow: wrap;
    justify-content: space-around;
  }
  div#different h5 {
    font-size: 2vw;
  }
  a.btn.btn-bordered.accordion {
    margin: 0 0 0 0;
    width: 40%;
    padding: 1.5vh 2vw;
    font-size: 2vw;
  }
  div#process h5 {
    font-size: 1.4vw;
    line-height: normal;
  }
  div#process h2 {
    font-size: 2vw;
    line-height: normal;
    margin: 0;
  }
  div#process h1 {
    margin-top: 0.2vw;
    margin-bottom: 0vw;
  }
  .process__section {
    display: flex;
    flex-direction: row;
  }
  .value__icon {
    width: 10.3vw;
    height: 10.3vw;
    line-height: 10.3vw;
  }
  .different__box {
    flex: 1 1 45%;
  }
  div#different img {
    height: 10vw;
  }
  a.btn.btn-bordered.accordion {
    width: 30%;
  }
  .jobs h2 {
    font-size: 2.2vw;
    line-height: normal;
    width: 65%;
  }
  .jobs__tab-label::after {
    font-size: 1.5vw;
  }
  .jobs__tab-label {
    padding: 2.5vw 3.5vw;
    margin: 2vw 0 0 0;
  }
  .jobs__tab-content ul li {
    font-size: 1.5vw;
  }
  .bg-resume h1.large {
    width: 100%;
    font-size: 3vw;
  }
  .bg-resume .button-group {
    float: none;
    margin: 1vw 0 0 0;
  }
  .bg-resume .main-title {
    top: 37%;
  }
  .bg-resume {
    height: 35vw;
  }
  img.value__img.career {
    width: 70%;
  }
  div#connect__inner ul .contact__bg-hover {
    flex: 1 0 40%;
  }
  ul.social li a {
    font-size: 1.8vw;
    line-height: 3.6vw;
    margin: 0.5vw;
    width: 3.5vw;
    height: 3.5vw;
  }
  .service_illustration img.vert-move {
    height: 70vh;
    margin: 0 0 0 2vw;
  }
  section.inner-banner.white__bg {
    height: 55%;
    margin: 0 0 2vw 0;
  }
  .service__content.mini-container {
    flex-direction: row;
    margin: 8vw auto 0 auto;
  }
  .advantages_text h1::after {
    height: 0.3vw;
  }
  .advantages_text h1::after {
    margin: -1.3vw 0;
  }
  .advantages_text h1:before {
    top: -1.5vw;
  }
  div#box h5 {
    line-height: 2.7vw;
  }
  .advantages_text h1 {
    font-size: 2.2vw;
  }
  div#ux_process .width-5 {
    float: left;
    padding: 3vw 5vw 3vw 0;
  }
  .lean_ux_process .ux-process .step {
    padding: 0 0 0 1vw;
  }
  div#ux_process h2 {
    font-size: 1.5vw;
    margin: 0;
  }
  .ux-process h5 {
    line-height: normal;
    font-size: 1.25vw;
    margin: 0.2vw 0 0.7vw 0;
  }
  .lean_ux_process .ux-process .step:after,
  .lean_ux_process .ux-process .step:before {
    height: calc(124% - 20px);
    bottom: 58%;
  }
  .web_development_img {
    padding: 0 1vw 0 0;
    height: 4vw;
  }
  .web_development {
    display: flex;
    flex-direction: row;
  }
  .web_development {
    flex-direction: row;
    padding: 1vw 0 0 0;
  }
  .web_development h1 {
    font-size: 2.2vw;
    line-height: normal;
  }
  .web_development h2 {
    font-size: 1.8vw;
    line-height: normal;
  }
  .web_development h5 {
    font-size: 1.2vw;
    padding: 0;
  }
  .about__us__img img.vert-move {
    padding: 1vw;
    margin-top: 6vw;
  }
  .web_development_card-body {
    padding: 0.5vw 1vw;
  }
  ul.capabilities-list .width-3 {
    width: 50%;
    padding: 2vw 0vw;
  }
  .capabilities-list ul li h5 {
    padding: 1.5vw 2vw 1.5vw 2vw;
  }
  .development_icon {
    width: 7.3vw;
    height: 7.3vw;
    line-height: 7vw;
  }
  .development_icon.primary img {
    width: 4vw;
  }
  .development_box h2 {
    font-size: 1.5vw;
    line-height: normal;
  }
  div#development {
    margin: 4vw 0 4vw 0;
  }
  .mobile__mobile-box h2 {
    font-size: 1.6vw;
    line-height: normal;
  }
  .mobile__mobile-box {
    padding: 1.5vw 3vw 0.5vw 3vw;
  }
  section.approach .width-7 {
    width: 100%;
  }
  section.approach .width-5 {
    width: 100%;
  }
  .approach_block .width-6:nth-child(2) .approach_single,
  .approach_block .width-6:nth-child(4) .approach_single {
    margin-top: 0vw;
  }
  .approach_block .width-6 {
    width: 49%;
    margin: 1vw 0;
    padding: 0 0.7vw;
  }
  .approach_single {
    padding: 1.5vw;
    border-radius: 1vw;
  }
  .tab__tab-box h2 a,
  section.work_portfolio h2 a {
    font-size: 2.5vw;
  }
  li.specialization_item::before {
    content: "";
    height: 15vw;
    background: #eceff1;
    position: absolute;
    transform: translateY(-50%) rotate(45deg);
    width: 24%;
    left: -3vw;
    top: 50%;
  }
  .specialization_img {
    float: left;
    width: 25%;
    height: auto;
    padding: 0 !important;
    background: none;
    margin: auto;
    position: relative;
  }
  .specialization_img h2 {
    font-size: 1.6vw;
    text-align: center;
    margin: 0 auto;
    align-items: center;
    padding: 12px !important;
    display: flex;
    justify-content: center;
    height: 10vw;
    vertical-align: middle;
    padding: 10px;
  }
  li.specialization_item:nth-child(even) .specialization_img h2 {
    float: none;
    text-align: right;
    padding: 3vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .specialization_text {
    width: 75%;
    padding: 0.5vw;
    display: block;
    vertical-align: middle;
    float: right;
  }
  li.specialization_item::after {
    content: "";
    height: 100%;
    background: #eceff1;
    position: absolute;
    width: 0.7vw;
    right: 0;
    top: 0;
  }
  img.img-list-src {
    padding: 1vw;
  }
  img.img-list-src {
    width: 50%;
    padding: 1vw 0;
    margin: 0;
  }
  li.specialization_item:nth-child(even) .specialization_img img.img-list-src {
    margin: 0vw 01vw 0 0;
  }
  li.specialization_item:nth-child(even) .specialization_img {
    padding: 0;
  }
  li.specialization_item:nth-child(even):before {
    right: -3.5vw;
    left: auto;
  }
  li.specialization_item:nth-child(even) .specialization_img {
    float: right;
    height: auto;
    text-align: right;
    padding: 0.9vw 0 0 0;
  }
  li.specialization_item:nth-child(even) .specialization_text {
    float: right;
    text-align: right;
  }
  .new_bg .footer {
    padding: 4vw 0 0 0;
  }
  .footer.second .width-3,
  .footer .width-3 {
    width: 25%;
  }
  section.bg-dark.new_bg.project {
    height: 90% !important;
    background: url(/assets/images/wave_footer1.svg);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: top !important;
  }
  section.bg-dark.new_bg {
    height: 55% !important;
    background: url(/assets/images/wave_footer.svg);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: top !important;
    margin-top: -17vw;
  }
  .solutions_block .width-4 {
    width: 33%;
    float: left;
  }
  .solutions_box {
    padding: 2vw 2vw;
    min-height: 34vw;
  }
  .solutions_box img {
    height: 8vw;
  }
  .test_list li {
    font-size: 1.2vw;
    padding: 0.5vw 0;
  }
  ul.test_list {
    padding: 1vw 0 0 0;
  }
  div#test .width-4 {
    width: 33%;
  }
  div#test {
    margin: 0 0 5vw 0;
    display: -webkit-box;
  }
  .checkmark:before {
    padding: 0px 1vw 0 0;
  }
  .width-8.upgrade_slider {
    width: 65%;
  }
  .upgrade_block .width-4 {
    width: 35%;
    text-align: center;
    padding: 5.5vw 0 0 0;
  }
  button.slick-prev.slick-arrow::before {
    left: 0.1vw;
    font-size: 2vw;
    top: 0;
  }
  button.slick-prev.slick-arrow {
    left: 30%;
  }
  button.slick-prev.slick-arrow,
  button.slick-next.slick-arrow {
    position: absolute;
    top: 42vw;
    width: 2.7vw;
    height: 2.7vw !important;
  }
  button.slick-next.slick-arrow::before {
    left: 0.2vw !important;
    font-size: 2vw;
    top: 0;
  }
  button.slick-next.slick-arrow {
    left: 49%;
  }
  .upgrade_icon {
    width: 7.3vw;
    height: 7.3vw;
  }
  .upgrade_icon img {
    height: 4vw;
  }
  .effect a {
    width: 4vw;
    height: 4vw;
    font-size: 2vw;
  }
  .blog_info ul li a,
  .blog_info ul li {
    font-size: 1.3vw;
  }
  .blockquote {
    margin: 3vw 4vw;
  }
  .project_bg2 .inner-banner__content h5 {
    font-size: 1.9vw;
    line-height: normal;
  }
  .bitwiser_block li {
    padding: 0.5vw 0;
    left: 0vw;
    font-size: 1.5vw;
  }
  .challenge_description p {
    padding: 0.5vw 0;
    font-size: 2.2vw;
  }
  .bitwiser_block li:before {
    left: -4vw;
    top: 1vw;
    width: 3%;
  }
  .tabs_wrapper ul.tabs {
    width: 35%;
    float: left;
  }
  .tabs_wrapper ul.tabs li {
    padding: 1.5vw 4vw;
    font-size: 1.8vw;
    margin: 1.5vw 0;
    border-radius: 1vw;
  }
  .tabs_wrapper .tab_bg {
    width: 60%;
    left: 10%;
    top: 5vw;
  }
  .bitwiser_block.shape li {
    padding: 1vw 0;
    left: 9%;
    width: 100%;
    font-size: 2vw;
  }
  .bitwiser_block.shape li:before {
    left: -5vw;
    top: 1.5vw;
    width: 9%;
  }
  ul.bitwiser_block.shape {
    top: 1vw;
    padding: 0 0 0 6vw;
  }
  .footer__bottom.second {
    bottom: 20.3vw;
  }
  .screenshot .fa-stack {
    height: 2.5em !important;
  }
  section.technology div#one-panel .owl-item {
    width: 16.6% !important;
  }
  section.technology div#two-panel .owl-item {
    width: 100% !important;
  }
  .screenshot .slick-slide img {
    width: 100%;
  }
  #pag-cover {
    width: 40%;
  }
  #links {
    width: 95%;
  }
  .pg-link {
    font-size: 2.5vw;
    width: 25%;
    margin: 0.9vw;
  }
  img.vert-move.ux_process {
    margin: 7.5vw 0 0 0;
  }
  section.development .pb-300 {
    padding-bottom: 20vw !important;
  }
  section.inner-banner.project-bg {
    height: 40%;
    margin: 0 0 4vw 0;
  }
  .recaptcha-msg {
    font-size: 1.5vw;
  }
  .main-banner__content-text {
    height: 25vh;
  }
  h5.main-banner__content-description {
    padding: 0 0 1vh 0;
  }
  h5.main-banner__content-description {
    padding: 0 0 1vh 0;
  }
  .testimonial__block {
    min-height: auto;
    padding: 1vw 2vw 1vw 0;
    margin: 0 1vw;
  }
  .testimonial__description {
    padding-left: 2vw;
  }
  .testimonial__name i {
    font-size: 1.5vw;
  }
}
