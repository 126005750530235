/* max-width: 720px */
@media (max-width: 45em) {
  a.hover-underline-animation {
    font-size:3.5vw;
    padding: 0.7vh 0;
  }
  .terms_of_use h3 {
    font-size: 5.5vw;
  }
  .privacy-policy h3{
    font-size: 5.5vw;
  }
  .approach_block .width-6{
    padding: 0;
  }
  .approach_block_content {
    margin: 8vw 0;
  }
  li.specialization_item {
    width: 100%;
  }
  section.approach .width-7, section.approach .width-5 {
    padding: 0;
  }
  .map_image{
    width: 100%;
  }
  .testimonial__pic {
    margin: 0 2vw 0 0;
  }
  .owl-carousel .owl-item .testimonial__pic img{
    width: 44% !important;
  }
  .testimonial__img{
    width: 42% !important;
  }
  .testimonials .testimonial__designation{
    padding: 0.5vh 0 !important;
  }
  .testimonial__name{
    margin-top: 18vw;
  }

  div#mobile{
    margin: 0;
  }
  .mobile__mobile-box {
    padding: 5.5vw 0;
  }
  img.vert-move {
    padding: 4vw 0;
  }
  .width-6 {
    width: 100%;
  }
  .quote.main-container {
    width: 88%;
  }
  .panels {
    padding: 0.7vw 0 0 0;
  }
  .abt_big_img {
    width: 92%;
  }
  .about__us .mini-container {
    width: 90%;
  }
  .deliever_content {
    width: 95%;
  }
  .process .mini-container {
    width: 90%;
  }
  .job .mini-container {
    width: 88%;
  }
  .about__us__content {
    padding: 2vw 0vw;
  }
  .web_development_card-body {
    padding: 0.5vw 0;
  }
  .new_bg .footer {
    padding: 2vw 0 0 0;
  }
  .connect > div.mini-container {
    width: 95%;
  }
  .service_detail_page h2 {
    font-size: 4.2vw;
    margin-bottom: 0.5vh;
  }
  .main-title {
    width: 100%;
  }
  .solutions .mini-container {
    width: 92%;
  }
  .upgrade .mini-container {
    width: 92%;
  }
  .connect__form {
    padding: 0;
  }
  .service_detail_page .footer ul.menu li a {
    font-size: 3.3vw;
    line-height: 2em;
  }
  .footer ul.menu li a {
    font-size: 3.5vw;
  }
  section.footer h5 {
    font-size: 3.5vw;
    line-height: normal;
  }
  .bitwiser_block li{
    font-size: 3.9vw;
  }
  .new_bg .main-title h5{
    font-size: 3.3vw;
  }
  .service_detail_page h4 {
    font-size: 3.5vw !important;
    margin: 0.4vh 0 !important;
  }
  .testim .cont div p {
    font-size: 3.8vw;
    line-height: normal;
  }
  ul.footer__social li a{
    margin: 0 3vw 0 0;
    font-size: 5.5vw;
  }
  .portfolio__box {
    padding: 0 0;
  }
  .tabs_wrapper ul.tabs{
    width: 100%;
  }
  .tabs_wrapper {
    width: 92%;
  }
  section.tab.portfolio .mini-container{
    width: 90%;
  }
  .ux-process h5 {
    font-size: 3.9vw;
  }
  div#ux_process h2{
    font-size: 5vw;
  }
  .lean_ux_process .main-container {
    width: 90%;
  }
  .connect__part:last-child {
    padding: 3.7vw 4.2vw 2vw 4.2vw;
  }
  .connect__part{
    padding: 0;
    margin: 0;
  }
  div#connect{
    margin: 0;
  }
  .main-banner__content-text .btn {
    font-size: 3.6vw;
    padding: 1vh 8vw;
  }
  .bg-resume {
    height: 70vw;
  }
  h1.large.main-banner__content-title {
    font-size: 5vw;
    margin-top: 0.8vh;
    line-height: 130%;
  }
  h5.main-banner__content-description {
    padding: 0 0 1.5vh 0;
    font-size: 3.5vw;
    margin: 0;
  }
  .ss_arrow {
    font-size: 27px !important;
  }
  .ss_left {
    padding: 1px 11px 3px 10px;
  }
  .ss_right {
    padding: 1px 9px 3px 13px;
  }
  .services .mini-container {
    width: 92%;
  }
  .inner-banner-content h5 {
    font-size: 14px;
  }
  /* .portfolio_detail_banner {
    height: 100% !important;
  } */
  .footer-cookie {
    flex-direction: column;
    justify-content: center;
  }
  .footer-cookie div {
    left: 0;
    right: 0;
    margin: 5px auto;
  }
  .main-banner__content-illustration {
    width: 100%;
  }
  .main-banner__content-illustration img {
    width: 100%;
  }
  .main-banner__content {
    height: 95%;
  }
  .main-banner__content-text {
    padding: 1.5vh 0 0 0;
  }
  .testimonial__description{
    app-read-more>.text-light {
        height: 187px;
      }
    
  }
  .testimonial__description > .text-light {
    line-height: 20px;
  }
  .blog__text h3 {
    font-size: 3.8vw;
  }
  .btn {
    font-size: 3.5vw;
    padding: 0.8vh 6vw;
  }
  .btn:hover{
    padding: 0.8vh 6vw;
  }
  .dark-wave h1.large {
    font-size: 4.15vw !important;
  }
  .recaptcha-msg, .recaptcha-msg a{
    font-size: 3.9vw;
    line-height: normal;
  }
  .abt_big_img img{
    max-width: 100%;
  }
  .technology .tabs_wrapper {
    display: block;
  }
  .tabs_wrapper ul.tabs {
    margin: 0 auto;
  }
  ul.bitwiser_block {
    padding: 0 !important;
  }
  .tabs_wrapper .tab_content {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
  .tabs_wrapper .tab_container {
    padding: 15vw;
    height: 37vh;
  }
  .main-banner__content-illustration img {
    height: 32vh;
    margin: 18vw 0 0 0;
  }
  .main-title h1 {
    font-size: 6.5vw;
    line-height: inherit;
  }
  app-read-more > .text-light {
    height: 180px;
  }
  .services__content a {
    padding: 3vw 0 !important;
    font-size: 4vw !important;
  }
  section.footer > div.main-container {
    padding: 0 3.5vw;
  }
  .footer__bottom h6.mar-0{
    font-size: 3.8vw;
  }
  .main-banner__content{
    width: 83%;
  }
  .next_prev_blog{
    display: inline;
  }
}


@media (max-width: 991px){
  .error-msg {
    padding-top: 1.5vw;
    font-size: 3vw;
  }
}
@media (max-width: 1024px){
  .recaptcha-msg, .recaptcha-msg a {
    font-size: 3.2vw;
    line-height: normal;
  }
  .form-checkbox label {
    font-size: 2.5vw;
    line-height: normal;
}
#acceptTerms{
  cursor: pointer;
  height: 9px;
  width: 9px;
  margin-right: 0.5rem;
}
}

@media (min-width: 1200px) and (max-width: 1264px){
  #return-to-top, #chat, #share {
    width: 7vw !important;
    height: 7vw !important;
  }
  #return-to-top {
    bottom: 10vw !important;
  }
  #return-to-top i, #chat i, #share i {
    left: 2.2vw !important;
    top: 1.7vw !important;
    font-size: 3vw !important;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .tabs .tab {
    font-size: 1.2vw;
  }
  a.hover-underline-animation {
    font-size: 1.5vw;
  }
}

@media (max-width: 768px) {
  .desktop_project_ss {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .mobile_project_ss {
    display: none;
  }
}



@media (max-width:760px) and (min-width:545px) {
  h1, .h1 {
    font-size: 4.5vw;
  }
  h2, .h2 {
    font-size: 3.5vw;
  }
  h4, .h4 {
    font-size: 3vw;
  }
  h5, .h5 {
    font-size: 2.5vw;
  }
  .tabs label{
    font-size: 2vw; 
    padding: 2.2vw 3vw;
  }
  .tabs_wrapper ul.tabs li {
    font-size: 3vw;
  }
  .service_detail_page h1.large {
    font-size: 4.1vw !important;
  }
  .service_detail_page .btn {
    font-size: 2.5vw !important;
  }
  .footer ul.menu li a {
    font-size: 2.5vw;
  }
  .footer__bottom__copyright h5 {
    font-size: 2.6vw;
  }
  .footer__bottom h6.mar-0 {
    font-size: 2.2vw;
  }
  .project_bg2 .inner-banner__content h5 {
    font-size: 2.8vw !important;
  }
  .inner-banner__background-img h1.large {
    font-size: 4.8vw;
  }
  h1.large.main-banner__content-title {
    font-size: 5vw !important;
  }
  h5.main-banner__content-description {
    font-size: 3vw;
  }
  .main-banner__content-text .btn {
    font-size: 2.8vw;
    padding: 1vh 6vw;
  }
  .main-banner__content-illustration img {
    height: 35vh;
    margin: 12vw 0 0 0;
  }
  .main-banner__content-text {
    padding: 2.5vh 0 0 0;
  }
  h1.large.main-banner__content-title {
    font-size: 4vw !important;
  }
}


@media (max-width:545px) and (min-width:500px) {
  .project_bg2 .inner-banner__content h5 {
    font-size: 3vw !important;
    margin: 16px auto;
    line-height: normal;
  }
  h1.large.main-banner__content-title {
    font-size: 5vw !important;
  }
  h5.main-banner__content-description {
    font-size: 3vw;
  }
  .main-banner__content-text .btn {
    font-size: 2.8vw;
    padding: 1vh 6vw;
  }
  .main-banner__content-illustration img {
    height: 35vh;
    margin: 12vw 0 0 0;
  }
  .main-banner__content-text {
    padding: 3vh 0 0 0;
  }
  h1.large.main-banner__content-title {
    font-size: 4vw !important;
  }
}

@media (max-width: 414px) and (min-width: 379px){
  .inner-banner__background-img h1.large {
    font-size: 6vw;
  }
  .tabs_wrapper .tab_bg{
    background-position: center;
    text-align: center;
  }
  .tabs_wrapper .tab_content {
    padding: 2vw 0 0 6vw;
  }
  .quote__content-description {
    font-size: 3.5vw;
  }
  h5, .h5 {
    font-size: 3.8vw;
    line-height: normal;
  }
  .tabs label{
    font-size: 3.5vw;
  }
  div#box h5 {
    line-height: normal;
  }
  .advantages_text h1{
    font-size: 5.5vw;
  }
  .portfolio_detail_banner {
    height: 90% !important;
  }
  .portfolio_detail_banner_img{
    top: 48%;
    transform: translateY(-55%);
  }
  .portfolio_detail_banner .inner-banner__background-img {
    margin-top: -15px;
  }
  .footer ul.menu li a {
    font-size: 3.8vw;
    line-height: 2.2em;
  }
  section.footer h5 {
    font-size: 3.9vw;
    line-height: normal;
  }
  ul.footer__social li a {
    font-size: 5.5vw;
    margin: 0 4vw 0 0;
  }
  .width-3 h2, .h2 {
    font-size: 4.4vw;
    margin: 1vh 0 0.5vh 0;
  }
  .main-banner__content-illustration img{
    height: 30vh;
    margin: 16vw 0 0 0
  }
  .testimonial__description .hover-underline-animation::after {
    bottom: 0.5vh;
  }
}

@media (max-width: 380px) and (min-width: 361px) {
  section.bg-dark.new_bg .main-title h1.large {
      padding: 4vw 0 0 0;
  }
  section.bg-dark.new_bg .button-group {
    margin: 0 0 0 0;
    display: inline-block;
  }
  section.bg-dark.new_bg .footer {
    padding: 3vw 0 0 0;
  }
  section.bg-dark.new_bg h2{
    font-size: 4.1vw;
  }
  section.bg-dark.new_bg .btn {
    font-size: 3vw;
    padding: 0.6vh 6vw;
  }
  section.bg-dark.new_bg h4 {
    font-size: 3.3vw;
  }
  section.bg-dark.new_bg ul.menu li a {
    font-size: 3.3vw;
    line-height: 6.5vw;
  }
  section.bg-dark.new_bg .main-title h1 {
    font-size: 5.5vw;
    line-height: inherit;
  }
  section.bg-dark.new_bg .new_bg .main-title h5 {
    font-size: 3.3vw;
  }
  .portfolio_detail_banner {
    height: 90% !important;
  }
  .inner-banner__background-img h1.large {
    font-size: 5.8vw;
    margin: 0 0 2.5vh 0;
  }
  .project_bg2 .inner-banner__content h5 {
    font-size: 3.5vw;
    line-height: 5.2vw;
  }
  .portfolio_detail_banner .inner-banner__background-img {
    margin-top: 0;
  }
  .main-banner__content-illustration img {
    height: 25vh;
    margin: 15vw 0 0 0;
  }
  h1.large.main-banner__content-title {
    font-size: 5vw;
  }
  .button-group {
    margin: 0.5vh 0 0 0;
  }
  .main-banner__content-text .btn {
    font-size: 3.5vw;
    padding: 1vh 6vw;
  }
  h5.main-banner__content-description {
    padding: 0 0 1.5vh 0;
    font-size: 3.8vw;
  }
  .quote__content-description {
    line-height: normal;
    font-size: 4vw;
  }
  section.footer h5{
    font-size: 4vw;
    line-height: normal;
  }
  .footer ul.menu li a {
    font-size: 3.8vw;
    line-height: 2.2em;
  }
  .btn {
    font-size: 3.8vw;
    padding: 0.8vh 6vw;
  }
  a.hover-underline-animation {
    font-size: 3.8vw;
  }
}


@media (max-width: 361px) {
  section.footer h5{
    font-size: 3.9vw;
    line-height: normal;
  }
  .footer ul.menu li a {
    font-size: 3.8vw;
    line-height: 2.2em;
  }
  .btn {
    font-size: 3.8vw;
    padding: 0.8vh 6vw;
  }
  a.hover-underline-animation {
    font-size: 3.8vw;
  }
  .portfolio_detail_banner {
    height: 85% !important;
  }
  .inner-banner__background-img h1.large {
    font-size: 5.8vw;
    margin: 0 0 2.2vh 0;
  }
  .project_bg2 .inner-banner__content h5 {
    font-size: 3.8vw;
    line-height: 5.6vw;
  }
  .portfolio_detail_banner {
    margin: 0 0 75px 0 !important;
  }
  .portfolio_detail_banner .inner-banner__background-img {
    margin-top: 0;
  }
  .quote__content-description {
    line-height: normal;
    font-size: 4vw;
  }
}




@media (max-width: 320px) {
  .portfolio_detail_banner {
    height: 80% !important;
  }
  h1.large.main-banner__content-title{
    font-size: 4.7vw;
  }
  h1.large.main-banner__content-title {
    font-size: 6vw;
    line-height: 8vw;
  }
  .main-banner__content .btn{
    font-size: 3.8vw;
    padding: 0.7vh 6vw;
  }
  h5.main-banner__content-description {
    font-size: 4.2vw;
  }
  .portfolio_detail_banner_img{
    top: 48%;
  }
  .tabs label{
    padding: 3.5vw 4vw;
    font-size: 3.2vw;
  }
}


