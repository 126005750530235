/* min-device-width: 1200px and max-device-width: 1600px */
@media (min-device-width: 75em) and (max-device-width: 100em) and (orientation: landscape) {
  h1.large,
  .h1.large {
    font-size: 2.2vw;
    line-height: normal;
  }
  .inner-banner__background-img h1.large {
    font-size: 2.2vw;
  }
  h2,
  .h2 {
    font-size: 1.6vw;
    line-height: normal;
  }
  .development_text h2 {
    line-height: 1 !important;
  }
  .project_bg2 .inner-banner__content h5 {
    font-size: 1.2vw;
    line-height: normal;
  }
  body.mobile-nav .header__logo a img {
    width: 60%;
    top: 8%;
    height: 16vh !important;
  }
  .main-banner {
    height: 90%;
    margin: 0 0 3vw 0;
  }
  .img-change {
    height: 9%;
  }
  .header__logo__wave-svg {
    width: 23%;
  }
  section.header.black .header__logo__wave-svg .img {
    display: none;
  }
  .new_bg .footer {
    padding: 2vw 0 0 0;
  }
  section.bg-dark.new_bg .main-title h1.large {
    padding: 10vw 0 0 0;
  }
  .header__logo a img {
    padding: 0.3vw 0 0 2vw;
    width: auto;
    margin: 0 !important;
  }
  .header__navigation {
    height: 7%;
  }
  .menu-toggle {
    top: 1%;
  }
  .main-banner__content-illustration img {
    padding: 2vw;
    height: 80vh;
    width: 90vw;
    margin: 0;
  }
  .main-banner__content {
    padding: 4vw 0 0 0;
  }
  .button-group {
    margin: 1vw 0;
  }
  #chat {
    right: 2vw;
  }
  #share {
    right: 2vw;
  }
  .social-buttons {
    right: 7.5vw;
  }
  #return-to-top {
    right: 2vw;
    bottom: 5.5vw;
  }
  .main-title {
    padding: 0 0 0.7vw 0;
  }
  .hover-underline-animation::after {
    height: 0.2vh;
    bottom: 0vw;
  }
  body.mobile-nav nav ul li a h5,
  body.mobile-nav nav ul li a h5:visited {
    font-size: 2.3vw;
  }
  nav ul li {
    margin-top: 0.5vw;
  }
  nav ul > li:last-of-type {
    top: auto;
  }
  /* section.experts.dark-wave {
  height: 50%;
  } */
  .dark-wave .main-title {
    transform: translate(-50%, -30%);
  }
  section.testimonial.top-shape {
    margin-top: -11%;
  }
  i.fas.fa-bars,
  i.fas.fa-times {
    font-size: 3rem;
  }
  nav ul li {
    margin: 1.5vw 0;
  }
  .btn {
    font-size: 1.5vw;
    padding: 1.5vh 3vw;
  }
  .btn:active,
  .btn:hover,
  .btn.btn-bordered.active {
    padding: 2vh 3vw;
    padding: 1.5vh 3vw;
  }
  .portfolio__box {
    padding: 0 4vw;
  }
  section.lean_ux_process.bg-light::after {
    display: none;
  }
  section.bg-dark.new_bg {
    height: 90% !important;
  }
  section.bg-dark.new_bg.project {
    height: 90% !important;
    background: url(/assets/images/wave_footer1.svg);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: top !important;
  }
  body.mobile-nav nav ul {
    flex-direction: column;
    display: flex;
    position: relative;
    padding: 4vw 0 10vw 0;
    top: 15%;
    overflow: auto;
  }
  .inner-banner {
    height: 72% !important;
    margin: 0px 0 5vw 0;
  }
  .service_illustration img.vert-move {
    height: 45vh;
  }
  .testimonial__block {
    min-height: 100%;
  }
  .testimonial__name {
    margin-top: 5.5vw;
  }
  .owl-carousel .owl-item .testimonial__pic img {
    width: 50% !important;
  }
  .testimonial__review h5 {
    font-size: 1vw;
  }
  .linkedIn-icon i {
    font-size: 22px !important;
  }
  img.services__shape {
    top: 1vw;
    height: 7.5vw;
  }
  #chat {
    bottom: 5.5vw;
  }
  #share,
  .social-buttons {
    bottom: 1vw;
  }
  .inner-banner__content {
    top: 30%;
  }
  section.inner-banner.project-bg .inner-banner__content {
    top: 25%;
  }
  .bitwiser_block li {
    font-size: 1.15vw;
  }
  .bitwiser_block.shape li {
    width: 22vw;
  }
  .challenge_description p {
    font-size: 1.5vw;
  }
  .tabs_wrapper .tab_bg {
    left: 20%;
  }
  img.vert-move.ux_process {
    padding: 0;
    margin: 4.5vw 0 0 0;
  }
  .bitwiser_block.shape li:before {
    left: -4vw;
    top: 0.5vw;
    width: 6%;
  }
  .tabs_wrapper ul.tabs {
    left: 15%;
  }
  section.technology .tabs label {
    padding: 0.7vw 3.3vw;
  }
  #return-to-top i,
  #chat i,
  #share i {
    left: 1.2vw;
    top: 0.65vw;
    font-size: 1.4vw;
  }
  .social-button i {
    font-size: 1.4vw;
  }
  .social-button {
    width: 3.6vw;
    height: 3.6vw;
  }
  section.testimonial {
    position: relative;
    margin-top: -9%;
  }
  .service__content.mini-container {
    margin: 7vw auto 0 auto;
  }
  .portfolio__box {
    padding: 0 2vw;
  }
  .capabilities-list ul li h5 {
    line-height: normal;
  }
  .new_bg.project {
    height: 60% !important;
  }
  img.img-list-src {
    width: 40%;
    padding: 1vw 0;
    margin: 0vw 0 0 1vw;
  }
  .specialization_img h2 {
    font-size: 1.5vw;
  }
  .specialization_img {
    padding: 0.6vw 0 0 0;
  }
  h5,
  .h5 {
    font-size: 1.2vw;
    line-height: normal;
  }
  .tabs label {
    font-size: 1vw;
  }
  a.hover-underline-animation {
    font-size: 1.19vw;
  }
  nav ul li a h5,
  nav ul li a h5:visited {
    font-size: 1.2vw;
  }
  .recaptcha-msg,
  .recaptcha-msg a {
    font-size: 1.1vw;
    line-height: 1.8vw;
  }
  .footer ul.menu li a {
    font-size: 1.15vw;
  }
  .footer__bottom__copyright h5 {
    font-size: 1.2vw;
  }
  h6,
  .h6 {
    font-size: 1.1vw;
  }
  .button-group a {
    font-size: 1.2vw;
    padding: 1.5vh 3vw;
  }
  .main-banner__content-text {
    padding: 4.7vh 0 0 0;
  }
  .portfolio_detail_banner_img {
    width: 52%;
    top: 48%;
    transform: translateY(-51%);
  }
}
