/* min-device-width: 720px and max-device-width: 992px */
@media (min-device-width: 45em) and (max-device-width: 62em) and (orientation: landscape) {
  h1,
  .h1 {
    font-size: 2.9vw;
    line-height: normal;
  }
  h2,
  .h2 {
    font-size: 2.2vw;
    line-height: normal;
  }
  h3,
  .h3 {
    font-size: 2.2vw;
    line-height: normal;
  }
  h4,
  .h4 {
    font-size: 2vw;
    line-height: normal;
  }
  h5,
  .h5 {
    font-size: 1.9vw;
    line-height: normal;
  }
  a.hover-underline-animation {
    font-size: 1.8vw;
  }
  body.mobile-nav .header__logo a img {
    width: 24%;
  }
  div#different img {
    height: 15vw;
  }
  div#different h5 {
    font-size: 2.2vw;
  }
  .header__logo__wave-svg {
    width: 39%;
  }
  body.mobile-nav nav ul li a h5,
  body.mobile-nav nav ul li a h5:visited {
    font-size: 2.5vw;
  }
  img.services__shape {
    top: 3vw;
    height: 9.5vw;
  }
  .services__service-box {
    padding: 2.5vw 1.6vw 3.1vw 1.6vw;
  }
  .img {
    height: 34vh !important;
  }
  .img-change {
    height: 20%;
  }
  .menu-toggle {
    top: 70%;
    transform: translateY(0);
    position: absolute;
  }
  body.mobile-nav .menu-toggle {
    top: 5%;
    position: absolute;
  }
  .inner-banner {
    height: 90%;
  }
  .header__logo a img {
    margin: 1vw 0 0 1.5vw;
    padding: 0.5vw 0 0 0;
  }
  section.experts.dark-wave {
    height: 80% !important;
  }
  section.inner-banner.white__bg {
    height: auto;
    margin: 0 0 10vw 0;
  }
  .service_illustration img.vert-move {
    height: 100vh;
    margin: 5vw 0 0 0;
  }
  .mobile__mobile-box {
    padding: 2.5vw 2vw;
    flex: 1 0 47%;
  }
  .approach_block .width-6 {
    padding: 0 0.5vw;
  }
  .solutions_box {
    min-height: 50vw;
  }
  .test_list li {
    font-size: 3vw;
    padding: 1vw 0;
  }
  img.benefits_icon {
    margin: 1vw 1vw 0 0px;
    width: 50%;
  }
  .header__logo {
    position: fixed;
    width: 100%;
    margin: 0 !important;
    height: 20%;
  }
  .header__logo a img {
    height: auto;
    width: 23%;
    top: 0.5%;
    margin: 0vw 0 0 4vw;
    float: left;
    padding: 0;
  }
  .header__logo a.stickylogo img {
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    margin: 0vw 0 0 1.5vw;
  }
  section.bg-dark.new_bg {
    height: 220% !important;
  }
  .services__service-box {
    flex: 1 0 31%;
  }
  .services__icon:after {
    width: 11.2vw;
    height: 11.2vw;
  }
  .services__icon {
    width: 10.3vw;
    height: 10.3vw;
    line-height: 10.3vw;
  }
  .form-group input {
    font-size: 1.5vw;
    height: 5vw;
  }
  .form-checkbox label {
    font-size: 1.5vw;
    height: 5vw;
  }
  #acceptTerms {
    height: 13px;
    width: 13px;
    margin-right: 0.5rem;
  }
  .contact__box .form-group textarea {
    font-size: 2vw;
    height: 10vw;
    padding: 2vw 3vw;
  }
  .main-banner {
    height: 130%;
  }
  .main-banner__content {
    flex-direction: row;
    padding: 8vw 0 0 0;
  }
  body.mobile-nav nav ul {
    flex-direction: column;
    display: flex;
    position: absolute;
    padding: 0vw 0 15vh 0;
    top: 25%;
    height: 90vh;
    overflow: auto;
    width: 100%;
  }
  .footer ul.menu li a {
    font-size: 2vw;
  }
  section.bg-dark.new_bg .main-title h1.large {
    font-size: 4vw;
  }
  .footer .width-3 h5 {
    font-size: 2vw;
    padding: 0 0 2vw 0 !important;
    line-height: normal;
  }
  .technology__icon img {
    width: 45%;
  }

  .error-msg {
    padding-top: 1vw;
    font-size: 1.5vw;
  }
}
