/* max-device-width: 992px */
@media (max-device-width: 62em) and (orientation: landscape) {
  h1.large,
  .h1.large {
    font-size: 3.3vw;
    line-height: normal;
  }
  h1,
  .h1 {
    font-size: 3.2vw;
    line-height: normal !important;
  }
  h2,
  .h2 {
    font-size: 2.8vw;
    line-height: normal;
  }
  h4,
  .h4 {
    font-size: 2.7vw;
    line-height: normal;
  }
  h5,
  .h5 {
    font-size: 2.5vw;
    line-height: normal;
  }
  .tabs label {
    font-size: 1.5vw;
  }
  a.hover-underline-animation {
    font-size: 2.2vw;
  }
  .tab__tab-box h2 a,
  section.work_portfolio h2 a {
    font-size: 2.5vw;
  }
  .dark-wave h1.large {
    font-size: 3.15vw;
  }
  .card h1.large {
    font-size: 8vw;
  }
  .pb-300 {
    padding-bottom: 25vw !important;
  }
  .mini-container {
    width: 85%;
  }
  .main-container {
    width: 94%;
  }
  .btn:active,
  .btn:hover,
  .btn.btn-bordered.active,
  .btn {
    padding: 1.5vh 4vw;
  }
  .button-group {
    margin: 8vh 0 0 0;
  }
  .btn {
    font-size: 2.2vw;
  }
  section.bg-dark.new_bg .main-title h1.large {
    font-size: 4vw;
  }
  .footer .width-3 h5 {
    font-size: 3vw;
    padding: 0 0 3vw 0 !important;
    line-height: normal;
  }
  .inner-banner {
    height: 110%;
  }
  body.mobile-nav .menu-toggle {
    top: 10%;
    position: absolute;
  }
  .img {
    height: 19vw !important;
  }
  .menu-toggle {
    top: 60%;
    transform: translateY(0);
    position: absolute;
  }
  body.mobile-nav .menu-toggle {
    top: 5%;
    position: absolute;
  }
  .img-change {
    height: 20%;
  }
  .header__logo {
    position: fixed;
    width: 100%;
    margin: 0 !important;
    height: 20%;
  }
  .header.black .header__logo {
    margin: 0vw 0 1vw 2vw;
  }
  .header__logo a img {
    margin: 0vw 0 0 1vw;
    top: 2%;
    width: 23%;
    left: 4%;
    position: fixed;
  }
  .header__logo a.stickylogo img {
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    margin: 0vw 0 0 1.5vw;
  }
  .header__logo__wave-svg {
    width: 42%;
  }
  .main-banner__content {
    width: 90%;
    justify-content: flex-end;
    flex-direction: column-reverse;
    padding: 8vw 0 0 0;
  }
  section.bg-dark.new_bg {
    height: 250% !important;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    margin-top: -17vw;
  }
  .footer ul.menu li a {
    font-size: 3vw;
    line-height: 2em;
  }
  body.mobile-nav .header__logo a img {
    width: 30%;
    top: 6%;
    margin: 0 auto;
  }
  body.mobile-nav nav ul li {
    margin-top: 0.1rem;
  }
  body.mobile-nav nav ul li a h5,
  body.mobile-nav nav ul li a h5:visited {
    font-size: 3.2vw;
  }
  .project_bg2 .inner-banner__content h5 {
    font-size: 3vw;
  }
  body.mobile-nav nav ul {
    flex-direction: column;
    display: flex;
    position: absolute;
    top: 25%;
    height: 90vh;
    overflow: auto;
    margin: 0 0 5vw 0;
    width: 100%;
  }
  .footer__bottom h6.mar-0 {
    font-size: 2vw;
  }
  .footer ul.menu li a {
    font-size: 2.8vw;
  }
  .pb-120 {
    padding-bottom: 14vw !important;
  }
  .main-banner {
    height: 240%;
    margin: 0 0 4vw 0;
  }
  .main-banner__content-illustration img {
    height: 100vh;
    margin: 0;
    padding: 0;
    margin: 12vw 0 0 0;
  }
  h5.main-banner__content-description {
    font-size: 2.5vw;
  }
  .services__content a {
    font-size: 1.8vw !important;
    padding: 1vh 0;
  }
  .dark-wave {
    height: 120% !important;
  }
  .technology__icon {
    width: 13.3vw;
    height: 13.3vw;
    line-height: 13.3vw;
  }
  div#different h5 {
    font-size: 3vw;
    padding: 2vw;
  }
  .jobs h2 {
    font-size: 2.5vw;
  }
  .jobs__tab-label::after {
    font-size: 2vw;
    line-height: normal;
  }
  .jobs__tab-label {
    padding: 3.5vw 3.5vw;
    margin: 5vw 0 0 0;
  }
  .value__icon {
    width: 14.3vw;
    height: 14.3vw;
    line-height: 14.3vw;
  }
  .wrapline a.more,
  .wrapline a.less {
    font-size: 2vw;
  }
  .service_illustration img.vert-move {
    height: 100vh;
    margin: 10vw 0 0 0;
  }
  .web_development {
    padding: 0;
  }
  .capabilities-list ul li h5 {
    padding: 3vw 5vw;
    font-size: 3vw;
    margin: 3vw 0;
  }
  .portfolio__box {
    padding: 0 2vw;
  }
  section.header.black .header__logo__wave-svg {
    width: 0;
  }
  .approach_block .width-6 {
    padding: 0 0.5vw;
  }
  .approach_single {
    padding: 5vw 3vw 3vw 3vw;
    margin: 1vw 0 0 0;
    min-height: 51vw;
  }
  button.slick-prev.slick-arrow {
    height: 7vw !important;
    width: 7vw;
    top: 32vw;
    left: 30%;
  }
  button.slick-prev.slick-arrow::before,
  button.slick-next.slick-arrow::before {
    font-size: 5vw;
  }
  button.slick-next.slick-arrow {
    height: 7vw !important;
    width: 7vw;
    top: 32vw;
    left: 47%;
  }
  .error__content {
    top: 60%;
    padding: 10vw 0;
  }
  h1.big_number {
    padding: 2vw 0 0 0;
  }
  section.inner-banner.project-bg {
    height: 220%;
    margin: 0 0 7vw 0;
  }
  .project_bg2 .inner-banner__content {
    top: 20%;
  }
  .new_bg .footer {
    padding: 9vw 0 0 0;
  }
  .bitwiser_block li:before {
    left: -6vw;
    width: 6%;
  }
  .tabs_wrapper ul.tabs li {
    padding: 3vw 7vw;
    margin: 3.7vw 0 0 0;
  }
  .bitwiser_block.shape li:before {
    top: 1.5vw;
    left: -6vw;
    width: 6%;
  }
  /* .owl-wrapper-outer {
    padding: 1vw 0 0 0;
  } */
  .owl-stage-outer {
    padding: 1vw 0 0 0;
  }
  ul.footer__social li a {
    font-size: 3.5vw;
  }
  .services__box {
    padding: 3vw 0 !important;
  }
  a.btn.btn-bordered.accordion {
    margin: 0vw 0 0 0;
    padding: 2.5vh 3vw;
    font-size: 2.2vw;
    width: auto;
  }
  body.mobile-nav nav ul {
    padding: 2vw 0 15vw 0;
  }
  .hover-underline-animation::after {
    height: 0.2vh;
    bottom: 1vw;
  }
  .approach_block .width-6:nth-child(2) .approach_single,
  .approach_block .width-6:nth-child(4) .approach_single {
    margin-top: 1vw;
  }
  .bitwiser_block li {
    font-size: 2.5vw;
  }
  .challenge_description p {
    font-size: 2.8vw;
  }
  .bitwiser_block li:before {
    left: -5vw;
    width: 4%;
  }
  .social-button i {
    font-size: 1.7vw;
  }
  .inner-banner__content {
    top: 29%;
  }
  .footer__bottom {
    /* margin: 1.4vw 0 0 0; */
  }
  .footer__bottom__copyright h5 {
    font-size: 2.2vw;
  }
  .technology .panels #two-panel .fa-chevron-circle-left:before,
  .technology .panels #two-panel .fa-chevron-circle-right:before {
    font-size: 32px;
    outline: 5px solid #fff;
    margin: 0vw auto;
    padding: 0vw 0;
    height: 32px;
    position: relative;
    width: 32px;
    outline-offset: -4px;
  }
  .error-msg {
    padding-top: 1vw;
    font-size: 5vw;
  }
  .project_bg2 .inner-banner__content h5 {
    font-size: 2.1vw !important;
  }
  div#ux_process h2 {
    font-size: 2.3vw;
  }
  .service_detail_page h2 {
    font-size: 3vw;
  }
  .ux-process h5 {
    font-size: 1.9vw;
  }
  .service_detail_page h1.large {
    font-size: 4.5vw !important;
  }
  .service_detail_page .footer ul.menu li a {
    font-size: 2vw;
    line-height: 5.5vw;
  }
  .service_detail_page .footer {
    padding: 8vh 0 0 0 !important;
  }
  .service_detail_page h4 {
    margin: 2.4vh 0 5vh 0 !important;
  }
  .service_detail_page h4 {
    font-size: 2.8vw !important;
  }
  .service_detail_page .btn {
    padding: 1.8vh 6vw !important;
  }
  .footer .width-3 h5 {
    font-size: 2.6vw;
  }
  .form-group input {
    font-size: 2vw;
  }
  .recaptcha-msg,
  .recaptcha-msg a {
    font-size: 2vw;
    line-height: normal;
  }
  .contact__box .form-group textarea {
    font-size: 2vw;
  }
  #return-to-top,
  #chat,
  #share {
    width: 7vw;
    height: 7vw;
  }
  #return-to-top {
    bottom: 12vw !important;
  }
  #return-to-top i,
  #chat i,
  #share i {
    left: 1.85vw;
    top: 1.5vw;
    font-size: 4vw;
  }
  .main-title h1 {
    font-size: 3.5vw;
  }
  ul.social li a {
    margin: 0 1vw;
    font-size: 3.2vw;
    line-height: 6.6vw;
    width: 6.5vw;
    height: 6.5vw;
  }
  .footer ul.menu li a {
    font-size: 2.5vw;
  }
  .tab__tab-box h2 a,
  section.work_portfolio h2 a {
    font-size: 3vw;
  }
  .mobile__mobile-box h2 {
    font-size: 2.5vw;
    line-height: normal;
  }
  .mobile__mobile-box {
    padding: 3.5vw;
  }
  .approach_single {
    min-height: auto;
  }
  .portfolio_detail_banner {
    height: 100% !important;
  }
  .inner-banner__background-img h1.large {
    font-size: 4vw;
    margin-bottom: 0.5vw;
  }
  .project_bg2 .inner-banner__content {
    top: 25%;
  }
  .portfolio_detail_banner_img {
    max-width: 70%;
  }
  .testim .cont div h2 {
    font-size: 3vw;
  }
  .testim .cont div p {
    font-size: 2.5vw;
    line-height: normal;
  }
  .tabs_wrapper .tab_container {
    padding: 20vh 0 20vh 15vh;
    height: auto;
  }
  .bitwiser_block.shape li {
    font-size: 2.5vw;
  }
  .bitwiser_block.shape li {
    width: 80%;
  }
  .card h4 {
    font-size: 2.5vw;
  }
  div#process h1 {
    margin: 0;
  }
  div#process h2 {
    font-size: 3vw;
  }
  .bg-resume h1.large {
    font-size: 4vw;
  }
  .bg-resume {
    height: 50vw;
  }
}
