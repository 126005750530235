/* min-device-width: 1024px and max-device-width: 1200px */
@media (min-device-width: 64em) and (max-device-width: 75em) and (orientation: landscape) {
    h1.large,
    .h1.large {
        font-size: 3vw;
        line-height: normal;
    }
    h1.large,
    .h1.large {
        font-size: 2.5vw;
        line-height: normal;
    }
    body.mobile-nav .header__logo a img {
        width: 60% !important;
        top: 5%;
        height: 11vh !important;
    }
    .main-banner {
        height: 90%;
        margin: 0 0 3vw 0;
    }
    .img {
        height: 28vw !important;
    }
    .img-change {
        height: 12vh;
    }
    .header__logo__wave-svg {
        width: 30%;
    }
    .img-change {
        height: 12% !important;
    }
    .header__logo {
        position: fixed;
        width: 100%;
        margin: 0 !important;
        height: 12%;
    }
    .header__navigation {
        width: 100%;
        display: block;
        align-items: center;
        height: 12%;
    }
    .header__logo a img {
        padding: 0.3vw 0 0 0;
        width: auto;
    }
    .main-banner__content-illustration img {
        padding: 2vw;
        height: 80vh;
        width: 90vw;
        margin: 0;
    }
    .main-banner__content {
        padding: 4vw 0 0 0;
    }
    .button-group {
        margin: 3.99vh 0 0 0;
    }
    #return-to-top {
        right: 2vw;
    }
    #chat {
        right: 2vw;
    }
    #share {
        right: 2vw;
    }
    .social-buttons{
    right: 7.5vw;
    }
    .main-title {
        padding: 0 1vw 2.7vw 1vw;
    }
    .hover-underline-animation::after {
        height: 0.2vh;
        bottom: 0vw;
    }
    body.mobile-nav nav ul li a h5,
    body.mobile-nav nav ul li a h5:visited {
        font-size: 2vw;
    }
    nav ul li {
        margin-top: 0.5vw;
    }
    nav ul > li:last-of-type {
        top: auto;
    }
    .inner-banner {
        height: 100%;
        margin: 0px 0 5vw 0;
    }
    .dark-wave .main-title {
        transform: translate(-50%, -30%);
    }
    section.testimonial.top-shape {
        margin-top: -11%;
    }
    i.fas.fa-bars,
    i.fas.fa-times {
        font-size: 3rem;
    }
    nav ul li {
        margin: 1.5vw 0;
    }
    section.inner-banner.white__bg {
        height: 100%;
    }
    .service_illustration img.vert-move {
        height: 100vh;
    }
    .btn:active,
    .btn:hover,
    .btn.btn-bordered.active {
        padding: 1.8vh 3.5vw;
    }
    .portfolio__box {
        padding: 0 4vw;
    }
    section.lean_ux_process.bg-light::after {
        display: none;
    }
    section.bg-dark.new_bg {
        height: 110% !important;
    }
    body.mobile-nav nav ul {
        flex-direction: column;
        display: flex;
        position: absolute;
        padding: 0 0 15vh 0;
        top: 25%;
        height: 90vh;
        overflow: auto;
        width: 100%;
    }
    .testimonial__name a {
        font-size: 1vw;
        line-height: normal;
    }
    .inner-banner {
        height: 70%;
    }
    .testimonial__block {
        min-height: 100%;
        padding: 0.9vw 2vw 0.9vw 0;
        margin: 0 1vw;
    }
    .testimonial__name{
        margin-top: 6vw;
    }
    .testimonial__description {
        padding-left: 2vw;
    }
    .serve__serve-box .owl-item img {
        width: auto;
    }
    img.services__shape {
        top: 1vw;
        height: 7.5vw;
    }
    .footer-cookie div{
        flex-direction: column;
        justify-content: center;
        left: 0;
        right: 0;
        margin: auto;
    }
    h1.large.main-banner__content-title {
        font-size: 3.2vw !important;
    }
    h5.main-banner__content-description {
        font-size: 1.5vw;
    }
    .main-banner__content-text .btn {
        font-size: 1.5vw;
    }
    .main-banner__content-illustration img {
        height: 50vh !important;
        margin: 0 0 0 0 !important;
    }
    .main-banner__content-illustration img {
        padding: 1vw;
    }
    .main-banner__content-text{
        height: 40vh;
    }
}
