
/* ==================================================
   #Animation KeyFrames
  ================================================== */

@keyframes animateWave {
0% {
    transform: scale(1, 0);
}
50% {
    transform: scale(1, 0);
}
75% {
    transform: scale(1, 0);
}
100% {
    transform: scale(1, 1);
}
}

@keyframes overlay {
0% {
    height: 0;
    /* animation-timing-function: cubic-bezier(0, 0.42, 1, 0); */
}
100% {
    height: 58.6vw;
    /* animation-timing-function: cubic-bezier(0, 0, 1, 0); */
}
}

@keyframes opacity {
0% {
    opacity: 0;
}
50% {
    opacity: 0;
}
75% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}

@-webkit-keyframes mover {
0% {
    transform: translateY(0);
}
100% {
    transform: translateY(-0.7vw);
}
}
@keyframes mover {
0% {
    transform: translateY(0);
}
100% {
    transform: translateY(-0.7vw);
}
}

@-webkit-keyframes fadeinup {
0% {
    opacity: 0;
    -webkit-transform: translateY(1.1vw);
}
100% {
    opacity: 1;
    -webkit-transform: translateY(0);
}
}

@-moz-keyframes fadeinup {
0% {
    opacity: 0;
    -moz-transform: translateY(1.1vw);
}
100% {
    opacity: 1;
    -moz-transform: translateY(0);
}
}

@-o-keyframes fadeinup {
0% {
    opacity: 0;
    -o-transform: translateY(1.1vw);
}
100% {
    opacity: 1;
    -o-transform: translateY(0);
}
}

@keyframes fadeinup {
0% {
    opacity: 0;
    transform: translateY(1.1vw);
}
100% {
    opacity: 1;
    transform: translateY(0);
}
}

@keyframes createpath{
    0% {
        fill-opacity: 0;
        stroke-dasharray: 1000;
      }
      100% {
        fill-opacity: 1;
        stroke-dashoffset: 0;
        stroke-dasharray: 20;
      }
}
@-webkit-keyframes fadeindown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-1.1vw);
    }	100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeindown {
    0% {
        opacity: 0;
        -moz-transform: translateY(-1.1vw);
    }

    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-o-keyframes fadeindown {
    0% {
        opacity: 0;
        -o-transform: translateY(-1.1vw);
    }

    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
}

@keyframes fadeindown {
    0% {
        opacity: 0;
        transform: translateY(-1.1vw);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes fadein {
from {
    opacity: 0;
}
to {
    opacity: 1;
}
}
@-moz-keyframes bouncein {
    0% {
        opacity: 0;
        -moz-transform: scale(.7);
    }
    100% {
        opacity: 1;
        -moz-transform: scale(1);
    }
}

@-o-keyframes bouncein {
    0% {
        opacity: 0;
        -o-transform: scale(.7);
    }
    100% {
        opacity: 1;
        -o-transform: scale(1);
    }
}

@keyframes bouncein {
    0% {
        opacity: 0;
        transform: scale(.7);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@-webkit-keyframes bounceinleft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
    }
    60% {
        -webkit-transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes bounceinleft {
    0% {
        opacity: 0;
        -moz-transform: translateX(-2000px);
    }

    60% {
        -moz-transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@-o-keyframes bounceinleft {
    0% {
        opacity: 0;
        -o-transform: translateX(-2000px);
    }

    60% {
        opacity: 1;
        -o-transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -o-transform: translateX(0);
    }
}

@keyframes bounceinleft {
    0% {
        opacity: 0;
        transform: translateX(-2000px);
    }

    60% {
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@-webkit-keyframes bounceinright {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
    }

    60% {
        -webkit-transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes bounceinright {
    0% {
        opacity: 0;
        -moz-transform: translateX(2000px);
    }

    60% {
        -moz-transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@-o-keyframes bounceinright {
    0% {
        opacity: 0;
        -o-transform: translateX(2000px);
    }

    60% {
        -o-transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -o-transform: translateX(0);
    }
}

@keyframes bounceinright {
    0% {
        opacity: 0;
        transform: translateX(2000px);
    }

    60% {
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
  
@keyframes slide-fill {
from {
    width: 20%;
    /* background-image: url("../assets/images/menu-wave.svg"); */
}
to {
    width: 100%;
    /* background-image: url("../assets/images/sticky-menu.svg"); */
}
}
@-webkit-keyframes imover {
0% { transform: translateY(0); }
100% { transform: translateY(-10px); }
}
@keyframes imover {
0% { transform: translateY(0); }
100% { transform: translateY(-10px); }
}


@-webkit-keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}








.animatable {
/* initially hide animatable objects */
visibility: hidden;

/* initially pause animatable objects their animations */
-webkit-animation-play-state: paused;
-moz-animation-play-state: paused;
-ms-animation-play-state: paused;
-o-animation-play-state: paused;
animation-play-state: paused;
}

/* show objects being animated */
/* Rotate */
figure img {
-webkit-transform: rotate(0) scale(1);
transform: rotate(0) scale(1);
-webkit-transition: 0.3s ease-in-out;
transition: 0.3s ease-in-out;
}
figure:hover img {
-webkit-transform: rotate(5deg) scale(1.1);
transform: rotate(5deg) scale(1.1);
}

.animated {
visibility: visible;
-webkit-animation-fill-mode: both;
-moz-animation-fill-mode: both;
-ms-animation-fill-mode: both;
-o-animation-fill-mode: both;
animation-fill-mode: both;

-webkit-animation-duration: 1s;
-moz-animation-duration: 1s;
-ms-animation-duration: 1s;
-o-animation-duration: 1s;
animation-duration: 1s;

-webkit-animation-play-state: running;
-moz-animation-play-state: running;
-ms-animation-play-state: running;
-o-animation-play-state: running;
animation-play-state: running;
}
.animated.fadeinup {
-webkit-animation-name: fadeinup;
-moz-animation-name: fadeinup;
-o-animation-name: fadeinup;
animation-name: fadeinup;
}
.animated.createpath{
-webkit-animation-name: createpath;
-moz-animation-name: createpath;
-o-animation-name: createpath;
animation-name: createpath;
}
.animated.bouncein {
-webkit-animation-name: bouncein;
-moz-animation-name: bouncein;
-o-animation-name: bouncein;
animation-name: bouncein;
}
.animated.fadeindown {
-webkit-animation-name: fadeindown;
-moz-animation-name: fadeindown;
-o-animation-name: fadeindown;
animation-name: fadeindown;
}
img.vert-move {
-webkit-animation: imover 2s infinite  alternate;
animation: imover 2s infinite  alternate;
padding: 2vw;
}
.animated.bounceinright {
-webkit-animation-name: bounceinright;
-moz-animation-name: bounceinright;
-o-animation-name: bounceinright;
animation-name: bounceinright;
}
.animated.bounceinleft {
-webkit-animation-name: bounceinleft;
-moz-animation-name: bounceinleft;
-o-animation-name: bounceinleft;
animation-name: bounceinleft;
}
  




.testim .cont div.active h2 {
	-webkit-animation: testim-content-in 0.4s ease-in-out forwards;
	-moz-animation: testim-content-in 0.4s ease-in-out forwards;
	-ms-animation: testim-content-in 0.4s ease-in-out forwards;
	-o-animation: testim-content-in 0.4s ease-in-out forwards;
	animation: testim-content-in 0.4s ease-in-out forwards;
}
.testim .cont div.active h5 {
	-webkit-animation: testim-content-in 0.5s ease-in-out forwards;
	-moz-animation: testim-content-in 0.5s ease-in-out forwards;
	-ms-animation: testim-content-in 0.5s ease-in-out forwards;
	-o-animation: testim-content-in 0.5s ease-in-out forwards;
	animation: testim-content-in 0.5s ease-in-out forwards;
}
.testim .cont div.inactive h2, .testim .cont div.inactive h5 {
	-webkit-animation: testim-content-out 0.4s ease-in-out forwards;
	-moz-animation: testim-content-out 0.4s ease-in-out forwards;
	-ms-animation: testim-content-out 0.4s ease-in-out forwards;
	-o-animation: testim-content-out 0.4s ease-in-out forwards;
	animation: testim-content-out 0.4s ease-in-out forwards;
}
.testim .cont div.inactive h4 {
	-webkit-animation: testim-content-out 0.5s ease-in-out forwards;
	-moz-animation: testim-content-out 0.5s ease-in-out forwards;
	-ms-animation: testim-content-out 0.5s ease-in-out forwards;
	-o-animation: testim-content-out 0.5s ease-in-out forwards;
	animation: testim-content-out 0.5s ease-in-out forwards;
}
@keyframes testim-scale {
	0% {
		box-shadow: 0px 0px 0px 0px #eee;
	}

	35% {
		box-shadow: 0px 0px 10px 5px #eee;
	}

	70% {
		box-shadow: 0px 0px 10px 5px #888888;
	}

	100% {
		box-shadow: 0px 0px 0px 0px #888888;
	}
}

@keyframes testim-content-in {
	from {
		opacity: 0;
		transform: translateY(100%);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes testim-content-out {
	from {
		opacity: 1;
		transform: translateY(0);
	}

	to {
		opacity: 0;
		transform: translateY(-100%);
	}
}









