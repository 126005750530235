/* min-width: 320px and max-width: 1200px */
@media (min-width: 20em) and (max-width: 75em) {
  h3,
  .h3 {
    font-size: 2.6vw;
    line-height: normal;
  }
  h6,
  .h6 {
    font-size: 2vw;
    font-weight: 400;
  }
  .btn {
    font-size: 2.2vw;
    padding: 0.7vh 5vw;
  }


  /* ==================================================
     #Header
    ================================================== */
  nav ul > li:last-of-type a .fa {
    font-size: 5vw;
  }
  nav ul > ol {
    top: 10vw;
  }
  nav ul > li:last-of-type {
    top: 0vw;
  }
  .hover-underline-animation::after {
    height: 0.2vh;
    bottom: 0vw;
  }
  .header__logo a img {
    height: auto;
    width: 30%;
    float: left;
  }
  .header__logo {
    margin: 0 0 0 2vw !important;
    flex: 1 0 100%;
    width: 100%;
  }
  /* .menu-toggle {
    top: 1.3rem;
    right: 2rem;
  } */
  .header__logo__wave-svg {
    width: 50%;
  }
  .img {
    height: 19vw;
  }

  /* ==================================================
     #Banner
    ================================================== */
  .main-banner {
    height: 90%;
  }
  .quote__content img {
    padding: 3vw;
  }
  .quote__content-description {
    margin: 1vw 0 3vw 0;
    float: initial;
    font-size: 2.5vw;
    line-height: normal;
  }
  section.footer h5 {
    font-size: 2.5vw;
    line-height: 1.7em;
  }

  .footer ul.menu li a {
    font-size: 3vw;
  }
  li.contact__bg-hover h5,
  li.contact__bg-hover h2 {
    padding: 2vw 0.7vw 0vw 0.7vw;
  }
  .testimonial__description {
    /* padding: 0.7vw 0 2vw 0; */
  }
  .blog__text {
    margin: 1.5vw;
  }
  .blog__text h3 {
    font-size: 2.2vw;
  }
  #pag-cover {
    width: 85%;
  }
  /* ==================================================
     #Inner Page
    ================================================== */
  .inner-banner {
    height: 70%;
  }
  .tabs label {
    padding: 3.5vw 4vw;
    font-size: 3.3vw;
  }
  .screenshot .slick-slide img {
    width: 100%;
  }
  section.bg-dark.new_bg {
    height: 75%;
  }
  .new_bg.project {
    height: 100% !important;
  }
  .experts.dark-wave h1.large {
    font-size: 3.15vw;
  }
  section.tab.portfolio .mini-container {
    width: 80%;
  }
  div#box h5 {
    line-height: normal;
  }
  .lean_ux_process .ux-process .step:after,
  .lean_ux_process .ux-process .step:before {
    height: calc(124% - 25px);
    bottom: 46%;
  }
  img.img-list-src {
    padding: 3vw 0;
  }
  section.test .width-4 {
    width: 100%;
  }
  img.benefits_icon {
    margin: 2.7vw 1vw 0 0px;
  }
  button.slick-prev.slick-arrow {
    bottom: -60%;
    left: 30%;
    top: unset;
  }
  button.slick-next.slick-arrow {
    bottom: -60%;
    left: 57%;
    top: unset;
  }
  .project_bg2 .inner-banner__content {
    top: 22%;
  }
  .bitwiser_block.shape li {
    font-size: 3.5vw;
  }
  .blog_info ul li a,
  .blog_info ul li {
    font-size: 1.7vw;
  }
  .effect a {
    width: 6vw;
    height: 6vw;
    font-size: 3vw;
  }
  .cookie-law-wrapper .mini-container {
    width: 97%;
    font-size: 15px !important;
  }
  .cookie-law-wrapper .copy a {
    font-size: 16px;
  }
  .portfolio_detail_banner {
    height: 85% !important;
  }
  .main-banner__content-text .btn {
    font-size: 1.8vw;
    padding: 1.5vh 4vw;
  }
  .service_detail_page .footer {
    padding: 2vh 0 0 0 !important;
  }
  .service_detail_page section.footer .width-3,
  .footer.second .width-3 {
    margin: 0 !important;
  }
  .service_detail_page h1.large {
    padding: 5vw 0 0 0 !important;
    margin: 0.5vh 0 !important;
    font-size: 5vw !important;
  }
  .service_detail_page .button-group {
    margin: 1vh 0 0 0 !important;
  }
  .service_detail_page .btn {
    font-size: 3.3vw !important;
    padding: 0.8vh 6vw !important;
  }
  #return-to-top {
    width: 12vw;
    height: 12vw;
    right: 2vw;
    bottom: 8.5vw;
  }
  #chat {
    width: 12vw;
    height: 12vw;
    right: 2vw;
    bottom: 19vw;
  }
  #share {
    width: 12vw;
    height: 12vw;
    right: 2vw;
    bottom: 3vw;
  }
  .social-buttons {
    right: 18vw;
    bottom: 3vw;
  }
  .social-button {
    width: 12vw;
    height: 12vw;
  }
  #return-to-top i,
  #chat i,
  #share i {
    left: 3.5vw;
    top: 2.6vw;
    font-size: 6vw;
  }
  .social-button i {
    font-size: 6vw;
  }
  .abt_big_img img{
    max-width: 95%;
  }
  .testimonial__description > .text-light {
    line-height: 24px !important;
  }
  .main-banner__content-illustration {
    padding: 0 0 0 0 !important;
  }
  .tabs_wrapper ul.tabs {
    margin: 0 auto;
  }
  ul.bitwiser_block {
    padding: 0;
  }
  .tabs_wrapper .tab_content {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
  .tabs_wrapper .tab_container {
    padding: 15vw 15vw 20vw 15vw;
    height: 44vh;
  }
  .download{
    margin-top: inherit;
  }
  .bitwiser.blog_detail {
    padding-top: 15.5vw;
  }
}
