/* min-width: 768px and max-width: 1200px */
@media (min-width: 48em) and (max-width: 75em) {
    h1.large,
    .h1.large {
        font-size: 4vw;
        line-height: normal;
    }
    h1,
    .h1 {
        font-size: 3.5vw;
        line-height: normal;
    }
    h2,
    .h2 {
        font-size: 3.3vw;
        line-height: normal;
    }
    h3,
    .h3 {
        font-size: 3vw;
        line-height: normal;
    }
    h4,
    .h4 {
        font-size: 2.6vw;
        line-height: normal;
    }
    h5,
    .h5 {
        font-size: 2.3vw;
        line-height: normal;
    }
    h6,
    .h6 {
        font-size: 2vw;
        line-height: normal;
        font-weight: 400;
    }
    a {
        font-size: 2.2vw;
        line-height: normal;
    }
    .tabs label{
        font-size: 2.3vw;
    }
    .footer ul.menu li a {
        font-size: 2.2vw;
    }
    .pb-300 {
        padding-bottom: 16vw !important;
    }
    .pb-200 {
        padding-bottom: 14vw !important;
    }
    .tabs label {
        padding: 1.5vw 2vw;
    }
    .value__icon {
        width: 13.3vw;
        height: 13.3vw;
        line-height: 13.3vw;
    }
    .card h4 {
        font-size: 2.5vw;
    }
    .card h1.large {
        font-size: 7vw;
    }
    .main-banner {
        height: 89%;
    }
    .main-banner__content-illustration img {
        height: 44vh;
        width: 60vw;
        margin: 12vw 0 0 0;
    }
    h5.main-banner__content-description {
        padding: 0 0 0 0;
    }
    .main-banner__content-illustration {
        width: 66%;
    }
    body.mobile-nav .header__logo a img {
        width: 45%;
        text-align: center;
        float: none;
        top: 3%;
        transform: translate(-50%, -10%);
        left: 50%;
        position: fixed;
    }
    .process__section img {
        width: 90%;
        display: block;
        margin: 0 auto;
    }
    .inner-banner {
        height: 50%;
    }
    .technology__icon {
        width: 14.3vw;
        height: 14.3vw;
        line-height: 14.3vw;
    }
    #blog_inner .blog__box {
        flex: 1 0 45%;
        margin: 2vw;
    }
    nav ul li a h5,
    nav ul li a h5:visited {
        font-size: 4vw;
    }
    .blog__text {
        margin: 1.5vw 3vw;
    }
    .blog__text h6 {
        font-size: 1.5vw;
    }
    div#different {
        flex-direction: initial;
    }
    .different__box {
        flex: 1 1 45%;
        float: left;
    }
    div#connect__inner ul .contact__bg-hover {
        margin: 2.2vw;
        padding: 5vw 1.4vw;
    }
    .mobile__mobile-box {
        padding: 4.5vw 3vw;
        flex: 1 0 45%;
    }
    .mobile__mobile-box h2 {
        font-size: 2.5vw;
        line-height: normal;
    }
    section.bg-dark.new_bg {
        height: 95%;
    }
    #pag-cover {
        width: 60%;
    }
    section.blog .mini-container {
        width: 95%;
    }
    .tab__tab-box h2 a,
    section.work_portfolio h2 a {
        font-size: 3vw;
    }
    .development_box h2 {
        font-size: 2.5vw;
        line-height: 3vw;
    }
    section.inner-banner.white__bg {
        height: 75%;
        margin: 0 0 16vw 0;
    }
    .ux-process h5 {
        font-size: 2.7vw;
    }
    div#ux_process h2 {
        font-size: 3vw;
    }
    .lean_ux_process .ux-process .step:after,
    .lean_ux_process .ux-process .step:before {
        height: calc(124% - 40px);
        bottom: 48%;
    }
    section.approach img {
        width: 100%;
    }
    .solutions_block .width-4 {
        width: 50%;
        float: left;
    }
    .solutions_box {
        padding: 2vw 3vw;
        margin: 1vw 0;
        min-height: 69vw;
    }
    .screenshot .slick-slide img {
        width: 100%;
    }

    .tabs_wrapper ul.tabs li {
        padding: 4vw;
        margin: 1vw 0;
        font-size: 2.5vw;
        border-radius: 1.5vw;
    }
    .tabs_wrapper ul.tabs li::before {
        left: -2vw;
    }
    .bitwiser_block.shape li {
        font-size: 2.5vw;
    }
    ul.bitwiser_block.shape {
        top: 0vw;
    }
    .tabs_wrapper .tab_bg {
        width: 100%;
        left: 0;
        margin: 0 auto;
        top: 0;
        text-align: center;
    }
    .bitwiser_block li:before {
        left: -4vw;
        top: 2vw;
        width: 3%;
    }
    .pg-link {
        width: 27%;
        font-size: 3vw;
        margin: 0.7vw;
        padding: 1.5vw;
    }
    #links {
        width: 60%;
    }
    .error__content {
        width: 50%;
    }
    h1.big_number {
        font-size: 11vw;
        padding: 4vw 0 0 0;
    }
    .error__message img {
        width: 60%;
    }
    .dark-wave {
        height: 30%;
        background: url(/assets/images/dark_wave.svg);
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
    }
    .capabilities-list ul li h5 {
        padding: 4vw 5vw;
        font-size: 3vw;
    }
    .approach_block .width-6 {
        padding: 0 1vw;
    }
    .approach_single {
        margin: 0 auto;
    }
    .project_bg2 .inner-banner__content h5 {
        font-size: 3vw;
        margin: 0 auto;
        line-height: normal;
    }
    .bg-light::before {
        top: -12.4vw;
    }
    .jobs__tab-label::after {
        font-size: 2.5vw;
        line-height: normal;
    }
    .jobs h2 {
        font-size: 3.2vw;
    }
    .contact__bg-hover::after {
        height: 1.2vw;
    }
    .test_list li {
        font-size: 3vw;
        padding: 1.2vw 0;
    }
    .experts{
        padding: 20vh 0;
    }
    .testimonial__block{
        flex: 1 0 50% !important;
    }
    .testimonials .column h1.large {
        font-size: 4vw;
    }
    .testimonials .column h4 {
        font-size: 2vw;
    }
    .testimonials .testimonial__block {
        padding: 3vw;
    }
    .testimonials .testimonial__block:before {
        top: 3.2vw;
        left: 4vw;
        transform: scale(1.3);
    }
    .testimonials .testimonial__block:after {
        right: 4vw;
        transform: rotate(180deg) scale(1.3);
    }
    .testimonial__review {
        margin-right: 1.6vw !important;
    }
    .footer-cookie {
        display: flex;
        flex-direction: row;
    }
}
