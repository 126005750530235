/* max-width: 1200px */
$primary: var(--primary-color);
@media (max-width: 75em) {
  body {
    letter-spacing: 0.1vw;
  }
  h1.large,
  .h1.large {
    font-size: 6vw;
  }
  h1,
  .h1 {
    font-size: 5.5vw;
    line-height: initial;
  }
  h2,
  .h2 {
    font-size: 5vw;
    line-height: normal;
  }
  h3,
  .h3 {
    font-size: 4.4vw;
    line-height: normal;
  }
  h4,
  .h4 {
    line-height: normal;
    font-size: 4vw;
  }
  h5,
  .h5 {
    font-size: 3.9vw;
    line-height: normal;
  }
  h6,
  .h6 {
    font-size: 3.6vw;
    font-weight: 400;
  }
  .width-6,
  .width-7,
  .width-8 {
    width: 98%;
  }
  .width-5,
  .width-4 {
    width: 80%;
  }
  .width-3 {
    width: 50%;
  }
  .btn {
    font-size: 3.5vw;
    padding: 1vh 8vw;
  }
  .btn:active,
  .btn:hover,
  .btn.btn-bordered.active {
    border: none;
    padding: 1.3vh 8vw;
    box-shadow: 0 0.7vw 1.83vw rgb(0 0 0 / 10%);
  }
  .mini-container {
    width: 87%;
  }
  .main-container {
    width: 95%;
  }
  .bg-light::before {
    position: absolute;
    top: -9.25vw;
    left: 0;
    bottom: 0;
    content: " ";
    background: url(/assets/images/shape-top-light-mobile.svg);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    width: 100%;
    z-index: -9;
    height: 17.5vw;
  }
  .bg-light::before {
    top: -16.4vw;
  }
  .bg-light::after {
    bottom: -10.6vw;
    height: 11.6vw;
  }
  .quote.main-container {
    width: 85%;
  }
  .pb-80 {
    padding-bottom: 10vw;
  }
  .pb-200 {
    padding-bottom: 22vw !important;
  }
  .pt-250 {
    padding-top: 23vw !important;
  }
  .pb-300 {
    padding-bottom: 25vw !important;
  }
  .pb-120 {
    padding-bottom: 16vw !important;
  }
  .main-title {
    width: 93%;
    line-height: normal;
  }

  /* ==================================================
   #Header
  ================================================== */
  section.header .header__navigation i.fas.fa-bars:before {
    content: url(/assets/images/mobile_menu.svg);
  }
  section.header .header__navigation i.fas.fa-bars.stickymenu:before {
    content: url(/assets/images/mobile_menu_black.svg);
  }
  section.header.black .header__navigation i.fas.fa-bars:before {
    content: url(/assets/images/mobile_menu_black.svg);
  }
  .header__logo a img {
    height: auto;
    width: 35%;
    margin: 1.5vw 0 0 5vw;
    float: left;
  }
  .header.black .header__logo a img {
    margin: 3.5vw 0 0 1.5vw;
  }
  .header__logo {
    position: fixed;
    width: 100%;
    margin: 0vw 0 0 1.5vw !important;
    height: 9.2vh;
  }
  body.mobile-nav .header__logo {
    width: 70%;
  }
  body.mobile-nav .menu-toggle {
    top: 6%;
    position: absolute;
  }
  .header.black .header__logo {
    margin: 3vw 0 1vw 2vw;
  }
  .header__logo__wave-svg {
    width: 58%;
  }
  .header.black a.stickylogo img {
    margin: 0vw 0 0 1.5vw;
  }
  section.header.black.active .header__logo__wave-svg img {
    display: none;
  }
  body.mobile-nav .header__logo__wave-svg {
    display: none;
  }
  body.mobile-nav .header__logo a img {
    width: 50%;
    text-align: center;
    float: none;
    top: 6%;
    margin: 0 auto;
    transform: translate(-50%, -10%);
    left: 50%;
    position: fixed;
  }
  body.mobile-nav .img-change {
    height: 10vh;
  }
  .img {
    height: 19vw;
  }
  .header__social-icon {
    display: none;
  }
  body.mobile-nav nav ul {
    margin-bottom: 30px;
  }
  nav ul li a h5,
  nav ul li a h5:visited {
    display: block;
    padding: 0 0.6vw;
    line-height: 1.7;
    margin-bottom: 1vw;
    font-size: 5vw;
    font-weight: 500;
    background: transparent;
    color: #2db0ff;
    text-decoration: none;
  }
  nav.menu-open ul li a h5:hover,
  nav.menu-open ul li a h5:visited:hover {
    color: #0082cb;
  }
  nav ul > li {
    display: none;
  }
  nav ul input {
    display: inline;
  }
  .menu-toggle {
    display: block;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
  }
  a.stickylogo img {
    margin: 0vw 0 0 2.5vw;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
  }
  .header__item {
    height: 1vw;
  }
  .header__navigation {
    width: 100%;
    display: block;
    align-items: center;
  }
  nav {
    padding-top: 0;
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  nav ul {
    display: block;
  }
  nav ul {
    flex-direction: column;
    display: flex;
  }
  nav ul li {
    margin-top: 0.5rem;
  }
  nav ul li a {
    margin: 0;
    font-size: 1.3rem;
  }
  .overlay.menu-open,
  nav.menu-open {
    display: flex;
    transform: scale(1);
    opacity: 1;
    height: 100vh;
    width: 100%;
  }
  .overlay.menu-open,
  nav.menu-open li {
    display: block;
  }
  header {
    position: relative;
    overflow: visible;
  }
  .img-change {
    height: 9vh;
  }
  body.mobile-nav .header__item {
    position: fixed;
    /* overflow: auto; */
    height: auto;
    width: 100%;
  }
  nav ul li {
    list-style: none;
    transform: translateX(100rem);
    animation: slideIn 0.5s forwards;
  }
  nav ul li:nth-child(1) {
    animation-delay: 0s;
  }
  nav ul li:nth-child(2) {
    animation-delay: 0.1s;
  }
  nav ul li:nth-child(3) {
    animation-delay: 0.2s;
  }
  nav ul li:nth-child(4) {
    animation-delay: 0.3s;
  }
  nav ul li:nth-child(5) {
    animation-delay: 0.4s;
  }
  nav ul li:nth-child(6) {
    animation-delay: 0.5s;
  }
  nav ul li:nth-child(7) {
    animation-delay: 0.6s;
  }
  nav ul li:nth-child(8) {
    animation-delay: 0.7s;
  }
  body.mobile-nav {
    position: fixed;
    overflow: hidden;
  }
  /* ==================================================
   #Banner
  ================================================== */
  .main-banner__content-illustration img {
    height: 33vh;
    margin: 22vw 0 0 0;
  }
  .main-banner {
    height: 80%;
    margin: 0 0 7vw 0;
  }
  .main-banner__content {
    flex-direction: column-reverse;
  }
  .main-banner__content-animation {
    animation: opacity 2s 1;
    padding: 3vw 0vw;
  }
  .main-banner__content-text {
    width: 100%;
    padding: 0;
  }
  .main-banner__background-img:before {
    height: 100%;
  }

  /* ==================================================
   #services
  ================================================== */
  .services__service-box {
    flex: 1 0 45%;
  }
  div#services .services__text h5 {
    display: none;
  }
  .services__icon {
    width: 15.3vw;
    height: 15.3vw;
    line-height: 15.3vw;
  }
  .services__icon:after {
    width: 17.2vw;
    height: 17.2vw;
  }
  a.hover-underline-animation {
    font-size: 2vw;
  }
  .services__service-box {
    padding: 5.5vw 1.6vw 5.1vw 1.6vw;
    margin: 0.5vw 0.7vw 1vw 0.7vw;
  }
  img.services__shape {
    top: 3.5vw;
    height: 17.5vw;
  }
  .services__text {
    margin: 4vw 0 0 0;
  }
  section.quote .button-group {
    margin: 1.99vh 0;
    display: inline-block;
  }

  /* ==================================================
   #Whom do we serve
  ================================================== */
  .serve__icon img {
    height: 100%;
    display: initial !important;
    width: unset !important;
  }
  .serve__serve-box {
    padding: 4vw;
    margin: 2vh 0;
  }
  .serve__blue {
    padding: 1vw;
    border-radius: 0 0 1.36vw 1.36vw;
  }
  .owl-theme .owl-controls .owl-buttons div {
    margin: 1.7vw !important;
  }
  span.fa-stack:hover i {
    font-size: 6vw;
  }
  span.fa-stack i {
    font-size: 9vw;
  }
  .serve span.fa-stack:hover i {
    font-size: 9vw;
  }
  .tabs .selector {
    border-radius: 4.5vw;
  }
  .panels .owl-theme .owl-controls {
    margin-top: 10px;
  }
  .tab__text {
    text-align: center;
  }
  .tab__tab-box h2 a,
  section.work_portfolio h2 a {
    font-size: 5vw;
  }
  .tab__tab-box {
    padding: 2vw 1vw 0vw 1vw;
    border-radius: 0.7vw;
    transition: all 0.3s ease 0s;
    background: #fff;
    flex: 1 0 33%;
    max-width: unset;
  }
  section.tab .main-container,
  section.tab.portfolio .mini-container {
    width: 95%;
  }
  .quote__content {
    flex-direction: column-reverse;
  }
  .quote__content-description {
    margin: 2vw 0 4vw 0;
    float: initial;
    font-size: 3vw;
    line-height: 4vw;
  }
  .quote__content-text {
    margin: 3vw 0 6vw 0;
    text-align: center;
  }
  .quote__content {
    padding: 10vw 2.5vw 15.5vw 2.5vw;
  }
  section.testimonial {
    position: relative;
    margin-top: -16%;
    padding: 1vw 0;
  }
  .testimonial__img {
    width: 53% !important;
  }
  .testimonial__block {
    min-height: auto;
    padding: 4vw 6vw 4vw 0;
    margin: 0 4vw;
  }
  .testimonial__description {
    padding-left: 6vw;
    padding-top: 7vw;
  }
  .testimonial__name {
    margin-top: 8vw;
  }
  .testimonial__pic img {
    width: 50% !important;
  }
  .blog__box {
    margin: 0 3.5vw;
  }
  .blog__text a.hover-underline-animation {
    bottom: 0;
    position: relative;
    padding: 1.5vw 4px;
  }
  .contact__bg-hover {
    padding: 7.2vw 6vw;
    margin: 4vw 4vw;
  }
  .contact__bg-hover::after {
    height: 2.2vw;
  }
  li.contact__bg-hover h5,
  li.contact__bg-hover h2 {
    padding: 2vw 0.7vw 0vw 0.7vw;
  }
  .form-group {
    margin-bottom: 0.8rem;
  }
  section.connect.bg-light {
    padding: 0 0 5vw;
  }
  .form-group input {
    font-size: 3vw;
    height: 9.4vw;
    padding: 0 3vw;
  }
  .form-checkbox label {
    font-size: 3vw;
    height: 9.4vw;
  }
  #acceptTerms {
    height: 13px;
    width: 13px;
    margin-right: 0.5rem;
  }
  .contact__box .form-group textarea {
    font-size: 3vw;
    height: 20vw;
    padding: 2vw;
  }
  .footer ul.menu li a {
    font-size: 3.8vw;
    line-height: 2.2em;
  }
  section.footer .width-3,
  .footer.second .width-3 {
    margin: 2vw 0;
  }
  section.footer h5 {
    font-size: 3.8vw;
    line-height: 1.7em;
  }
  ul.footer__social li a {
    font-size: 5vw;
  }
  .footer__bottom__copyright h5 {
    font-weight: 500;
    font-size: 3.9vw;
    line-height: normal;
  }
  .footer__bottom h6.mar-0 {
    font-size: 2.9vw;
    padding: 0.5vw 0 1vw 0;
    line-height: normal;
  }

  /* ==================================================
   #Services page
  ================================================== */
  .inner-banner__background-img h1.large {
    font-size: 5.15vw;
    margin-bottom: 1vw;
  }
  .services__box {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 7vw 0 !important;
  }
  .services__content {
    padding: 0vw 1vw 2vw 1vw;
  }
  .inner-banner__background-img {
    background-image: url(/assets/images/mobile_bg.svg);
  }
  .inner-banner {
    height: 40%;
    margin: 0 0 11vw 0;
  }
  img.vert-move {
    padding: 4vw;
  }
  .inner-banner__content.text-center {
    width: 100%;
  }
  .inner-banner__content {
    top: 34%;
    padding: 0 10vw;
    display: block;
  }
  .dark-wave {
    height: 40%;
    background: url(/assets/images/dark_wave_mobile.svg);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }
  .dark-wave h1.large {
    font-size: 4.15vw;
  }
  .technology__icon {
    width: 20.3vw;
    height: 20.3vw;
    line-height: 20.3vw;
  }
  .reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  /* .owl-wrapper-outer {
    padding: 3.5vw 0 !important;
  } */
  .owl-stage-outer {
    padding: 3.5vw 0 !important;
  }
  #testimonial .owl-stage-outer {
    padding: 18.5vw 0 3.5vw 0 !important;
  }
  div#serve {
    margin: 2.5vw 0;
  }
  section.technology {
    padding: 1vw 0 0 0;
  }
  .animated.bounceinleft,
  .animated.bounceinright {
    -webkit-animation-name: fadeinup;
    -moz-animation-name: fadeinup;
    -o-animation-name: fadeinup;
    animation-name: fadeinup;
  }
  .side-title {
    text-align: center;
  }

  /* ==================================================
   #About Us
  ================================================== */
  section.value.bg-light .main-title {
    float: left;
  }
  .column {
    padding: 3.5%;
    flex: 1 0 50%;
    text-align: center;
  }
  .card h1.large {
    font-size: 12vw;
    margin: 0;
  }
  .card h4 {
    font-size: 3.9vw;
    margin: 0;
  }
  img.value__img {
    margin: 2vw auto;
    width: 100%;
  }
  .value__icon {
    width: 20.3vw;
    height: 20.3vw;
    line-height: 20.3vw;
  }
  .process__section__back {
    display: none;
  }
  .process__section {
    display: flex;
    flex-direction: column;
  }
  .process__no.width-3 {
    width: 30%;
    padding: 5vw 0 0 0;
  }
  div#process .width-9 {
    width: 70%;
    padding: 2vw;
  }
  .process__section:nth-child(3) {
    text-align: right;
  }
  .process__img img {
    padding: 8vw 0 0 0;
  }
  div#process h2 {
    font-size: 4vw;
    line-height: normal;
    margin: 0;
    padding: 0.7vw 0 0 0;
  }
  div#process h1 {
    margin-top: 3vw;
    margin-bottom: 0;
  }
  section.bg-dark.new_bg {
    background: url(/assets/images/wave_footer_mobile.svg);
    height: 100% !important;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }
  .new_bg .footer {
    padding: 11vw 0 0 0;
  }
  .main-title h5 {
    padding: 0 0.5vw;
  }
  section.bg-dark.new_bg .main-title h1.large {
    padding: 9vw 0 0 0;
  }

  /* ==================================================
   #blog
  ================================================== */
  #blog_inner .blog__box {
    flex: 1 0 95%;
    margin: 5vw auto;
    min-height: auto;
  }
  .blog__text {
    margin: 3vw 4vw;
  }
  .blog__text h3 {
    clear: both;
    font-weight: 600;
  }
  #pag-cover {
    width: 92%;
  }
  .pg-link {
    width: 29%;
    font-size: 5vw;
    margin: 0.7vw;
    padding: 3.2vw 2vw;
  }
  #links {
    width: 100%;
  }

  /* ==================================================
   #testimonials
  ================================================== */
  .testimonials {
    .column {
      padding: 2.5%;
      flex: 1 0 25%;

      h1.large {
        font-size: 5vw;
      }

      h4 {
        font-size: 2.6vw;
      }
    }

    .testimonial__block {
      padding: 4vw;
    }
    .testimonial__block:before {
      top: 2vw;
      transform: scale(0.8);
    }
    .testimonial__block:after {
      right: 3vw;
      transform: rotate(180deg) scale(0.8);
    }
  }

  /* ==================================================
   #Career  
  ================================================== */
  div#different {
    flex-direction: column;
  }
  div#different img {
    height: 25vw;
  }
  div#different h5 {
    font-size: 4vw;
    font-weight: 500;
  }
  .jobs__tab-label {
    padding: 5.5vw 3.5vw;
    margin: 7vw 0 0 0;
  }
  .jobs .jobs__col:last-child {
    margin-left: 0;
  }
  .jobs h2 {
    font-size: 4.2vw;
    line-height: normal;
    width: 53%;
  }
  a.btn.btn-bordered.accordion {
    margin: 3vw 0 0 0;
    width: 40%;
    padding: 1.8vh 5vw;
    font-size: 3.5vw;
  }
  .jobs__tab-label::after {
    font-size: 3.5vw;
  }
  .jobs__tab-content ul {
    padding: 1vw 4vw;
  }
  .jobs__tab-content ul li {
    font-size: 3.5vw;
  }
  .jobs {
    padding: 0 0 7vw 0;
  }
  .bg-resume {
    text-align: center;
    height: 94vw;
    float: none !important;
    margin: 0 auto;
  }
  .bg-resume h1.large {
    width: 100%;
    font-size: 5vw;
    line-height: normal;
  }
  .bg-resume .button-group {
    float: none;
    margin: 4vw 0 0 0;
  }
  .bg-resume .main-title {
    top: 29%;
  }
  img.value__img.career {
    width: 100%;
  }

  /* ==================================================
   #Contact Us
  ================================================== */
  div#connect__inner ul .contact__bg-hover {
    margin: 2.2vw;
    min-height: auto;
    flex: 1 0 98%;
  }
  .responsive-map {
    padding-bottom: 50%;
    position: relative;
    z-index: 999;
  }
  .connect__form {
    padding: 1vw 1vw 7vw 1vw;
    width: 90%;
    margin: 0 auto;
  }
  ul.social li a {
    margin: 2vw;
    font-size: 4.2vw;
    line-height: 7.6vw;
    width: 7.5vw;
    height: 7.5vw;
  }
  div#connect .main-title {
    padding: 0 1vw 0.7vw 4vw;
    text-align: left;
    width: 100%;
  }
  div#connect .main-title h5 {
    width: 100%;
  }
  div#connect button.btn.btn-bordered.active {
    margin: 2vw 0 0 0;
  }

  /* ==================================================
   #Blog Detail
  ================================================== */
  section.blog_detail img {
    width: 100%;
  }
  .effect a {
    width: 7vw;
    height: 7vw;
    font-size: 4vw;
  }
  .blog_info ul li {
    font-size: 2vw;
  }
  .blog_info ul li a {
    font-size: 2.2vw;
  }
  .blog_info ul li::after {
    height: 100%;
    width: 1px;
    bottom: -10%;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }
  .blog_content {
    width: 100%;
  }
  .blog_info ul {
    padding: 2.5vw 0;
  }
  .blockquote {
    padding: 3.5vw 5vw;
    margin: 6vw 2vw;
  }

  /* ==================================================
   #404
  ================================================== */
  .error__message img {
    width: 70%;
  }
  h1.big_number {
    font-size: 17vw;
    padding: 5vw 0 0 0;
  }
  .error__message .button-group {
    margin: 3vh 0 0 0;
    display: inline-block;
  }
  .error__content {
    width: 70%;
  }

  /* ==================================================
   #UI / UX
  ================================================== */
  .service__content.mini-container {
    flex-direction: column-reverse;
  }
  .service_illustration img.vert-move {
    height: 35vh;
    margin: 18vw 0 0 0;
  }
  section.inner-banner.white__bg {
    height: auto;
    margin: 0 0 15vw 0;
  }
  .advantages_img {
    display: none;
  }
  .advantages_text {
    width: 100%;
  }
  .advantages_text h1:before {
    top: -3.17vw;
  }
  .advantages_text h1::after {
    height: 0.7vw;
  }
  .lean_ux_process .main-container {
    width: 80%;
  }
  div#ux_process .width-5 {
    float: none;
    margin: 3vw auto;
  }
  .lean_ux_process .ux-process .step:after,
  .lean_ux_process .ux-process .step:before {
    left: -14px;
  }
  .ux-process h5 {
    font-size: 3.6vw;
  }
  .lean_ux_process .ux-process .step .scroll-point {
    left: -20px;
  }
  .lean_ux_process .ux-process .step:after,
  .lean_ux_process .ux-process .step:before {
    height: calc(124% - 14px);
    bottom: 45%;
  }
  .lean_ux_process .ux-process .step {
    padding: 0 0 0 4vw;
  }
  div#ux_process h2 {
    font-size: 3.7vw;
    margin: 0;
  }
  div#box h5 {
    line-height: normal;
  }

  /* ==================================================
   #Web Development
  ================================================== */
  .web_development {
    display: flex;
    flex-direction: column;
    padding: 5vw 0 0 0;
  }
  .web_development_img {
    padding: 0 3vw 0 0;
    height: 12vw;
  }
  ul.capabilities-list .width-3 {
    width: 98%;
    padding: 3vw 0 0.7vw 0;
  }
  .capabilities-list ul li h5 {
    padding: 6vw 5vw;
  }
  .capabilities-list ul li h5:after {
    right: 3.5vw;
  }
  .web_development_media-body {
    text-align: left;
  }

  /* ==================================================
   #Mobile Development
  ================================================== */
  .hover-underline-animation::after {
    height: 0.2vh;
    bottom: -2vw;
  }
  .mobile__mobile-box {
    padding: 5.5vw 5vw;
    margin: 0.5vw 0.7vw;
    transition: all 0.3s ease 0s;
    flex: 1 0 98%;
  }
  .development_icon.primary img {
    width: 6vw;
  }
  .development_icon {
    width: 12.3vw;
    height: 12.3vw;
    line-height: 11.5vw;
  }
  .development_box h2 {
    font-size: 3.5vw;
    line-height: 4vw;
  }
  .web_development_media-body h5 {
    line-height: 2;
    padding: 1.5vw 0 0 0;
  }
  .mobile__mobile-box h2 {
    font-size: 4.5vw;
    line-height: 6vw;
  }
  div#development {
    margin: 6vw 0;
  }
  img.development_img {
    margin: 9vw auto 0 auto;
  }

  /* ==================================================
   #API Development
  ================================================== */
  div#test .width-4 {
    width: 100%;
  }
  section.approach .width-7,
  section.approach .width-5 {
    width: 100%;
  }
  .approach_block .width-6 {
    padding: 0 5.5vw;
    margin: 0 auto;
    width: 100%;
  }
  .approach_single {
    padding: 5vw 5vw 3vw 5vw;
    border-radius: 2vw;
    margin: 5vw 1vw;
  }
  .approach_block .width-6:nth-child(2) .approach_single,
  .approach_block .width-6:nth-child(4) .approach_single {
    margin-top: 5vw;
  }
  .approach_block_content {
    margin: 8vw 3vw;
  }
  .approach_block_content img {
    padding: 3vw;
  }
  li.specialization_item {
    margin: 4vw auto 0 auto;
  }
  .portfolio__text h2,
  .portfolio__text h5 {
    margin: 0.5vw 0;
  }
  li.specialization_item::before {
    content: "";
    height: auto;
    background: #eceff1;
    position: absolute;
    transform: unset;
    width: 100%;
    left: 0;
    top: 0;
  }
  img.img-list-src {
    width: 50%;
    margin: 4vw 0 0 0;
  }
  .specialization_img {
    float: none;
    text-align: center;
    width: 100%;
    height: 100%;
    background: #eceff1;
    margin: 0 auto;
  }
  li.specialization_item::after {
    content: "";
    height: 2.5vw;
    background: #eceff1;
    position: relative;
    width: 100%;
    right: 0;
    bottom: 0;
    display: block;
  }
  .specialization_img h2 {
    text-align: center;
    margin: 0 auto;
    padding: 0;
  }
  li.specialization_item:hover .specialization_img {
    background: #ffffff;
  }
  li.specialization_item:nth-child(even) .specialization_img h2 {
    float: none;
    text-align: center;
    padding: 0;
  }
  .specialization_text {
    width: 100%;
    padding: 1.5vw 4vw 3vw 4vw;
    display: block;
    vertical-align: middle;
    float: none;
    text-align: center;
  }
  li.specialization_item {
    padding: 0;
  }
  li.specialization_item:nth-child(even):before {
    right: 0;
    left: 0;
  }
  li.specialization_item:nth-child(even) .specialization_img {
    float: none;
    height: auto;
    text-align: center;
  }
  .technology .panels #two-panel .fa-chevron-circle-left:before {
    content: "";
    font-size: 35px;
    margin: 0vw auto;
    padding: 1.7vw 0;
    background: #385a9a;
    height: 22px;
    width: 22px;
    color: #fff;
    border-radius: 50%;
    font-weight: bold;
    position: absolute;
    box-shadow: 0 0.2vw 2vw 0 rgb(0 0 0 / 10%);
  }
  .technology .panels #two-panel .fa-chevron-circle-right:before {
    content: "";
    font-size: 35px;
    height: 22px;
    width: 22px;
    padding: 1.7vw 0vw;
    margin: 0vw 0 0 -6vw;
    background: #385a9a;
    color: #fff;
    border-radius: 50%;
    font-weight: bold;
    position: absolute;
    box-shadow: 0 0.2vw 2vw 0 rgb(0 0 0 / 10%);
  }
  li.specialization_item:nth-child(even) .specialization_img img.img-list-src {
    margin: 5vw 0 0 0;
  }
  li.specialization_item:nth-child(even) .specialization_text {
    float: none;
    text-align: center;
  }

  /* ==================================================
   #testing
  ================================================== */
  ul.test_list {
    padding: 0vw 0 0 5.5vw;
  }
  .solutions_block {
    display: inline-block;
    padding: 0 0 4vw 0;
  }
  .test_list li {
    font-size: 4vw;
    padding: 2vw 0;
    line-height: normal;
  }
  .checkmark:before {
    padding: 0px 2vw 0 0;
  }
  .solutions_block .width-4 {
    width: 80%;
    float: none;
    margin: 0 auto;
  }
  .solutions_box {
    padding: 4vw;
    margin: 7vw 0;
  }
  .solutions_box img {
    height: 16vw;
  }
  .technology #tab .tabs {
    border-radius: 4.5vw;
  }
  .solutions_box h5 {
    margin: 2vw 0 0 0;
    line-height: 1.9;
  }

  /* ==================================================
   #Upgradation
  ================================================== */
  .solutions_item {
    display: inline-block;
  }
  img.benefits_icon {
    float: right;
    margin: 3.7vw 0 0 0px;
    width: 60%;
  }
  .upgrade_block {
    margin: 0;
  }
  .upgrade_block .width-4 {
    width: 100%;
    text-align: center;
    padding: 0;
  }
  .upgrade_bg {
    height: 10vw;
  }
  button.slick-prev.slick-arrow::before,
  button.slick-next.slick-arrow::before {
    font-size: 8vw;
  }
  button.slick-next.slick-arrow::before {
    font-size: 8vw;
  }
  button.slick-prev.slick-arrow {
    width: 10vw;
    height: 10vw !important;
    top: 70vw;
    left: 31%;
  }
  button.slick-next.slick-arrow {
    width: 10vw;
    height: 10vw !important;
    top: 70vw;
    left: 52%;
  }
  .slider.slick-initialized.slick-slider {
    margin: 0 auto 13vw auto;
  }
  .upgrade_icon {
    width: 16.3vw;
    height: 16.3vw;
  }
  .upgrade_icon img {
    height: 9vw;
  }
  .upgrade__box {
    padding: 7vw 4vw 3vw 4vw;
  }
  .slider-nav-thumbnails.slick-initialized.slick-slider {
    top: -5.5vw;
  }
  .slick-slider {
    width: 70%;
  }

  /* ==================================================
   #Project page
  ================================================== */
  .inner-banner__background-img.project_bg2 {
    background-image: url(/assets/images/banner-bg-project-mobile.svg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-width: 100%;
    height: 100%;
    overflow: hidden;
  }
  section.inner-banner.project-bg {
    height: 70%;
    margin: 0 0 11vw 0;
  }
  ul.bitwiser_block {
    padding: 0 5vw;
  }
  .project_bg2 .inner-banner__content {
    top: 24%;
    padding: 0 2vw 0 5vw;
    text-align: left;
    display: block;
  }
  .project_bg2 .inner-banner__content h5 {
    font-size: 4.1vw;
    margin: 0 auto;
    line-height: normal;
  }
  section.technology .tabs label {
    padding: 3vw 5.2vw;
    font-size: 3.5vw;
  }
  .bitwiser_block li {
    padding: 1.5vw 0;
    left: 4vw;
    line-height: normal;
    font-size: 2.3vw;
  }
  .challenge_description p {
    padding: 1.5vw 0;
    line-height: normal;
    font-size: 3.5vw;
  }
  .bitwiser_block li:before {
    left: -5vw;
    top: 2vw;
    width: 4%;
  }
  .tabs_wrapper ul.tabs {
    width: 90%;
    text-align: center;
  }
  .tabs_wrapper ul.tabs li {
    padding: 5vw 7vw;
    margin: 1.7vw 0;
    display: block;
    border-radius: 1.5vw;
  }
  .tabs_wrapper ul.tabs li {
    font-size: 4.5vw;
  }
  .tabs_wrapper ul.tabs li::before {
    /* content: url(/assets/images/bitwiser_technology_icon.svg); */
    content: var(--background);
    position: relative;
    left: -3vw;
    top: 0;
    vertical-align: middle;
  }
  .tabs_wrapper .tab_bg {
    margin-top: 30px;
    left: 0;
    width: 100%;
  }
  .tabs_wrapper {
    width: 90%;
  }
  .bitwiser_block.shape li:before {
    left: -8vw;
    top: 2.5vw;
    width: 8%;
  }
  .bitwiser_block.shape li {
    padding: 1.5vw 0;
    width: 100%;
    font-size: 4vw;
    font-weight: 600;
  }
  .new_bg.project {
    background: url(/assets/images/wave_footer_mobile-project.svg);
    height: 80% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
  .slick-center .slick-slide {
    padding: 2vw;
  }
  .screenshot .slick-slide img {
    display: flex;
    width: 100%;
  }
  .technology .blog__img img {
    height: 38vw;
  }
  .technology #two:checked ~ .panels #two-panel .fa-chevron-circle-right:before,
  .technology #two:checked ~ .panels #two-panel .fa-chevron-circle-left:before {
    font-size: 6vw;
    margin: -3vw;
  }
  .screenshot a.pic-prev.slick-arrow {
    left: 4vw;
  }
  .screenshot a.pic-next.slick-arrow {
    right: 4vw;
  }
  .screenshot .fa-stack {
    background: #385a9a;
    background: $primary;
    /* height: 3.9em !important; */
  }
  .wrapline a.more,
  .wrapline a.less {
    float: left;
    width: 100%;
    padding: 1vw 0;
    color: #2eb1fe;
  }
  .owl-theme .owl-controls .owl-buttons div {
    background: none !important;
  }

  .error-msg {
    padding-top: 1.5vw;
    font-size: 2vw;
  }

  /* footer cookie */
  .cookie-law-wrapper .mini-container {
    width: 97%;
    font-size: 15px !important;
  }
  .tab__tab-box h2 a,
  section.work_portfolio h2 a {
    font-size: 5vw;
  }
  .portfolio_detail_banner {
    height: 65% !important;
    margin: 0 0 100px 0 !important;
  }
  .portfolio_detail_banner_img {
    width: 100% !important;
    margin-top: 0px;
  }
  .client_serve_small {
    display: none !important;
  }
  .client_serve_large {
    display: block !important;
  }
  #return-to-top {
    width: 13.7vw;
    height: 13.7vw;
    right: 2vw;
    bottom: 2vw;
  }
  #chat {
    width: 13.7vw;
    height: 13.7vw;
    right: 2vw;
    bottom: 6.4vw;
  }
  #share {
    width: 13.7vw;
    height: 13.7vw;
    right: 2vw;
    bottom: 2vw;
  }
  .social-buttons {
    right: 8vw;
    bottom: 1vw;
  }
  .social-button {
    width: 4.2vw;
    height: 4.2vw;
  }
  #return-to-top i,
  #chat i,
  #share i {
    left: 3.8vw;
    top: 3.5vw;
    font-size: 6.5vw;
  }
  .social-button i {
    font-size: 1.7vw;
  }
  .testimonial__pic {
    margin: 0 1vw 0 0;
    // width: 15%;
  }
}
@media (max-width: 50em) {
  .service_btn {
    display: block;
  }
}
